export const formatPhoneNumber = (phoneNumber: string) => {
  // Handle both formats: with and without the +55 prefix
  const regexWithPrefix = /^\+55(\d{2})(\d{4,5})(\d{4})$/;
  const regexWithoutPrefix = /^(\d{2})(\d{4,5})(\d{4})$/;

  // Try matching with +55 prefix first
  let match = phoneNumber.match(regexWithPrefix);

  if (match) {
    const [, areaCode, firstPart, secondPart] = match;
    return `+55 (${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Then try matching without prefix
  match = phoneNumber.match(regexWithoutPrefix);

  if (match) {
    const [, areaCode, firstPart, secondPart] = match;
    return `+55 (${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Return original if no match
  return phoneNumber;
};

export const validatePhoneNumber = (phoneNumber: string): string => {
  // Remove any non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Validate phone number format
  if (cleaned.length < 10 || cleaned.length > 11) {
    throw new Error("Formato de número de telefone inválido");
  }

  // Ensure it starts with Brazil country code
  return cleaned.startsWith("55") ? cleaned : `55${cleaned}`;
};

const stripNonNumeric = (str: string) => str.replace(/\D/g, "");

export const cleanPhoneNumber = (phone: string): string => {
  // Remove all non-numeric characters
  let numericValue = stripNonNumeric(phone);

  // If empty, return empty string
  if (!numericValue) return "";

  // Handle carrier codes (e.g., 015, 021, 041) first
  const carrierCodePattern = /^0[1-9][1-9]/;
  if (carrierCodePattern.test(numericValue)) {
    numericValue = numericValue.slice(3);
  }

  // Then handle cases where the number starts with 55
  if (numericValue.startsWith("55")) {
    numericValue = numericValue.slice(2);
  }

  // Extract only the DDD (area code) and the phone number
  return numericValue.slice(-11);
};

export const normalizePhoneNumber = (numericValue: string) => {
  if (!numericValue) return "";

  const normalizedValue = numericValue.slice(-11);

  if (normalizedValue.length < 2) return normalizedValue;

  let formatted = `(${normalizedValue.slice(0, 2)}`;

  if (normalizedValue.length < 3) return formatted;
  formatted += ") ";

  if (normalizedValue.length <= 7) {
    formatted += normalizedValue.slice(2);
  } else {
    formatted += `${normalizedValue.slice(2, 7)}-${normalizedValue.slice(7)}`;
  }

  return formatted;
};
