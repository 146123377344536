import { Card, CardBody, Chip } from "@nextui-org/react";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { getFormattedAppointmentOption } from "../../../utils/getFormattedAppointmentOption";
import {
  AtSign,
  BriefcaseMedical,
  CalendarClock,
  HandCoins,
  HeartHandshake,
  Hospital,
  MapPin,
  Phone,
} from "lucide-react";
import { AppointmentInfo } from "../../Atoms/AppointmentInfo";
import { Clinic } from "../../../models/Clinic";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";

interface PatientDetails {
  appointment: Appointment;
  provider?: ProviderInformation;
  clinic?: Clinic;
  currentUser: any;
}

export const PatientDetails: React.FC<PatientDetails> = ({
  appointment,
  provider,
  clinic,
  currentUser,
}) => {
  const renderAppointmentSuggestions = () => {
    if (appointment.status !== AppointmentStatus.WAITING_PROVIDER) return null;

    const options = [
      appointment.option1,
      appointment.option2,
      appointment.option3,
    ].filter(Boolean);
    const { formatDate } = getFormattedAppointmentOption();

    return (
      <div className="flex flex-col gap-2">
        <h5 className="mx-1">
          {options.length} {options.length === 1 ? "Sugestão" : "Sugestões"}:
        </h5>
        <div className="flex flex-col flex-wrap gap-2">
          {options.map((option, index) => {
            const { formattedDate, formattedTime } = formatDate(
              option as string,
              currentUser.timezone
            );
            return (
              <Chip
                key={index}
                color="warning"
                startContent={<CalendarClock className="w-4" />}
                variant="flat"
                className="p-2 text-warning-500 bg-warning-50 font-medium"
              >
                {formattedDate} - {formattedTime}
              </Chip>
            );
          })}
        </div>
      </div>
    );
  };

  const entity = clinic ? clinic : provider;
  if (!entity) return null;

  return (
    <div className="flex flex-col gap-8">
      {renderAppointmentSuggestions()}
      <Card radius="md" shadow="none">
        <CardBody className="gap-4 p-0">
          <div className="flex gap-2">
            <FirebaseAvatar
              imageUrl={entity.imageUrl || ""}
              name={entity.name || ""}
            />
            <div className="flex flex-col py-0.5">
              <span className="text-sm font-medium text-default-900">
                {entity.name}
              </span>
              <span className="text-sm font-medium text-default-400">
                {clinic
                  ? clinic?.cnpj
                  : `${provider?.professionalCouncil.conselho}: ${provider?.professionalCouncil.numero} - ${provider?.professionalCouncil.estado}`}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-1 mx-0.5">
            <AppointmentInfo
              icon={<MapPin className="w-4" />}
              text={
                (provider?.relatedClinic?.address as string) ||
                (clinic?.address as string)
              }
            />
            {!clinic && (
              <AppointmentInfo
                icon={<Hospital className="w-4" />}
                text={provider?.relatedClinic?.name as string}
              />
            )}
            <AppointmentInfo
              icon={<Phone className="w-4" />}
              text={
                (provider?.relatedClinic?.phoneNumber as string) ||
                (clinic?.phoneNumber as string)
              }
            />
            <AppointmentInfo
              icon={<AtSign className="w-4" />}
              text={entity.email as string}
            />
            {appointment.serviceId && (
              <AppointmentInfo
                icon={<BriefcaseMedical className="w-4" />}
                text={appointment.serviceId as string}
                className="flex gap-1"
              />
            )}
            {appointment.paymentMethod && (
              <AppointmentInfo
                icon={<HandCoins className="w-4" />}
                text={appointment.paymentMethod as string}
                className="flex gap-1"
              />
            )}
            {appointment.healthInsurance && (
              <AppointmentInfo
                icon={<HeartHandshake className="w-4" />}
                text={appointment.healthInsurance}
                className="flex gap-1 text-success-700"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
