import { dayjs } from "../libs/dayjsConfig";
import { Schedule } from "../models/Schedule";

// Timezones
export const timezones = Intl.supportedValuesOf("timeZone");
export const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Format to use when storing dates in the database (always in UTC)
const DB_FORMAT = "YYYY-MM-DDTHH:mm:00.000Z";

// Format to use for display in the Brazilian format
const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";

export const DateUtils = {
  // Convert a date to the format used in the database (always in UTC)
  toDBFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string | null => {
    if (!date) return null;
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).utc().format(DB_FORMAT)
      : d.utc().format(DB_FORMAT);
  },

  // Convert a query date to the format used in the database, using a threshold
  queryToDBFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string | null => {
    if (!date) return null;
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).utc().endOf("day").format(DB_FORMAT)
      : d.utc().endOf("day").format(DB_FORMAT);
  },

  // Parse a date from the database format (UTC) to a specific timezone
  fromDBFormat: (
    dateString: string | null | undefined,
    timezone?: string
  ): dayjs.Dayjs | null => {
    if (!dateString) return null;
    const utcDate = dayjs.utc(dateString);
    return timezone ? utcDate.tz(timezone) : utcDate;
  },

  // Format a date for display in the specified timezone
  toDisplayFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string => {
    if (!date) return "";
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).format(DISPLAY_FORMAT)
      : d.format(DISPLAY_FORMAT);
  },

  // Parse a date from the display format in a specific timezone
  fromDisplayFormat: (
    dateString: string,
    timezone?: string
  ): dayjs.Dayjs | null => {
    const parsedDate = timezone
      ? dayjs.tz(dateString, DISPLAY_FORMAT, timezone)
      : dayjs(dateString, DISPLAY_FORMAT);
    return parsedDate.isValid() ? parsedDate : null;
  },

  // Get separate date and time strings for display in a specific timezone
  getFormattedDateAndTime: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ) => {
    if (!date) return { formattedDate: "", formattedTime: "" };
    const parsedDate = timezone ? dayjs(date).tz(timezone) : dayjs(date);
    return {
      formattedDate: parsedDate.format("DD/MM/YYYY"),
      formattedTime: parsedDate.format("HH:mm"),
    };
  },

  // Compare two dates (useful for sorting)
  compareDates: (
    a: dayjs.Dayjs | Date | string | null,
    b: dayjs.Dayjs | Date | string | null
  ): number => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    return dayjs(a).valueOf() - dayjs(b).valueOf();
  },

  // Check if a date is valid
  isValidDate: (date: Date | string | dayjs.Dayjs): boolean => {
    return dayjs(date).isValid();
  },

  // Get current date and time in a specific timezone
  now: (timezone?: string): dayjs.Dayjs => {
    return timezone ? dayjs().tz(timezone) : dayjs();
  },

  // Convert a date from one timezone to another
  convertTimezone: (
    date: dayjs.Dayjs | Date | string,
    fromTz: string,
    toTz: string
  ): dayjs.Dayjs => {
    return dayjs(date).tz(fromTz).tz(toTz);
  },

  calculateEndTime: (
    startTime: string,
    appointmentType: "exam" | "appointment",
    examId: string | undefined,
    schedule: Schedule,
    timezone: string
  ): string => {
    const start = dayjs(startTime).tz(timezone);
    let duration: number;

    if (appointmentType === "exam" && examId && schedule.exams?.[examId]) {
      duration = schedule.exams[examId].duration || 0;
    } else if (
      appointmentType === "appointment" &&
      examId &&
      schedule.services?.[examId]
    ) {
      duration = schedule.services[examId].duration || 0;
    } else {
      duration = parseInt(schedule.defaultDuration || "60", 10);
    }

    return start.add(duration, "minute").format();
  },

  isCancelable: (
    appointmentDate: string | null | undefined,
    timezone?: string
  ): boolean => {
    if (!appointmentDate) return false;
    const now = DateUtils.now(timezone);
    const appointment = DateUtils.fromDBFormat(appointmentDate, timezone);
    if (!appointment) return false;
    return appointment.diff(now, "hour") > 24;
  },
};

export function calculateAvailableTimesPerDay(
  timezone: string,
  schedule: Schedule,
  isExam: boolean,
  examId?: string,
  serviceId?: string,
  conflictingAppointments?: Set<string>
): string[] {
  if (!schedule?.initTime || !schedule?.endTime) return [];

  const now = DateUtils.now(timezone);

  const initTime = DateUtils.fromDBFormat(schedule.initTime, timezone);
  const init = initTime
    ? now
        .clone()
        .hour(initTime.hour())
        .minute(initTime.minute())
        .second(initTime.second())
    : now.clone().hour(0).minute(0).second(0);

  const end = DateUtils.fromDBFormat(schedule.endTime, timezone);

  if (!init || !end) return [];

  let durationMinutes: number = 60; // Default to 1 hour

  if (isExam && examId && schedule.exams?.[examId]) {
    // Fix: Add null/undefined check and provide default
    durationMinutes = schedule.exams[examId].duration ?? 60;
  } else if (!isExam && serviceId && schedule.services?.[serviceId]) {
    // Fix: Add null/undefined check and provide default
    durationMinutes = schedule.services[serviceId].duration ?? 60;
  } else if (schedule.defaultDuration) {
    const defaultDuration = DateUtils.fromDBFormat(
      schedule.defaultDuration,
      timezone
    );
    durationMinutes = defaultDuration
      ? defaultDuration.hour() * 60 + defaultDuration.minute()
      : 60; // Default to 1 hour if parsing fails
  }

  const availableTimes = new Set<string>();
  let currentTime = init.clone();
  const endOfDay = currentTime.clone().endOf("day");

  while (currentTime.isSameOrBefore(endOfDay)) {
    const timeString = currentTime.format("HH:mm");
    const dayOfWeek = currentTime.day();

    const isWithinWorkHours =
      (currentTime.isSameOrAfter(init) && currentTime.isBefore(end)) ||
      (end.isBefore(init) &&
        (currentTime.isSameOrAfter(init) || currentTime.isBefore(end)));

    if (
      isWithinWorkHours &&
      !schedule.disabledTimes?.[dayOfWeek]?.includes(timeString)
    ) {
      const slotEnd = currentTime.add(durationMinutes, "minute");

      if (slotEnd.isSameOrBefore(endOfDay)) {
        const formattedSlotTime = DateUtils.toDBFormat(currentTime, timezone);
        const isConflictingAppointment = conflictingAppointments?.has(
          formattedSlotTime as string
        );

        if (!isConflictingAppointment) {
          availableTimes.add(timeString);
        }
      }
    }

    currentTime = currentTime.add(durationMinutes, "minute");
  }
  return Array.from(availableTimes).sort();
}
