import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ProviderInformation } from "../../models/ProviderInformation";

export const useProviderDetailsQuery = (
  providerId: string | undefined | null
) => {
  return useQuery<ProviderInformation>({
    queryKey: ["providers", providerId],
    queryFn: async () => {
      const docRef = doc(fireStoreDb, "providers", providerId as string);
      const docSnap = await getDoc(docRef);

      const data = docSnap.data();
      if (!data) return {} as ProviderInformation;

      return {
        ...data,
        id: docSnap.id,
      } as ProviderInformation;
    },
    enabled: Boolean(providerId),
  });
};
