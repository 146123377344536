import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ProviderInformation } from "../../models/ProviderInformation";
import { useAuth } from "../../contexts/auth.context";
import { Appointment, AppointmentStatus } from "../../models/Appointment";
import { fireStoreDb, functions } from "../../services/firebase/firebase";
import { generateEmailRequest } from "../../utils/generateEmailRequest";
import { EmailStructure } from "../../models/EmailStructure";
import { httpsCallable } from "firebase/functions";
import { Clinic } from "../../models/Clinic";

interface BaseScheduleAppointmentParams {
  selectedOptions: string[];
  paymentMethod?: string | null;
  healthInsurance?: string | null;
}

interface RegularAppointmentParams extends BaseScheduleAppointmentParams {
  provider: ProviderInformation | undefined;
  serviceId: string;
  reason: string;
}

interface ExamAppointmentParams extends BaseScheduleAppointmentParams {
  clinic: Clinic;
  examId: string;
  provider?: ProviderInformation;
}

type ScheduleAppointmentParams =
  | RegularAppointmentParams
  | ExamAppointmentParams;

function removeUndefined<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined)
  ) as T;
}

export const useScheduleAppointment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  return useMutation<string, Error, ScheduleAppointmentParams>({
    mutationFn: async (params) => {
      const isExamAppointment = "examId" in params;

      if (!isExamAppointment && !params.provider) {
        throw new Error(
          "Provider information is required for regular appointments"
        );
      }

      let newAppointment: Partial<Appointment> = {
        status: AppointmentStatus.WAITING_PROVIDER,
        userId: currentUser?.uid,
        option1: params.selectedOptions[0] || null,
        option2: params.selectedOptions[1] || null,
        option3: params.selectedOptions[2] || null,
        paymentMethod: params.paymentMethod || null,
        healthInsurance: params.healthInsurance || null,
      };

      if (isExamAppointment) {
        newAppointment = {
          ...newAppointment,
          clinicId: params.clinic.id || null,
          examId: params.examId,
          providerId: params.provider?.id || null,
        };
      } else {
        newAppointment = {
          ...newAppointment,
          providerId: params.provider?.id || null,
          serviceId: params.serviceId,
          reason: params.reason,
          clinicId: null,
        };
      }

      // Remove undefined values
      newAppointment = removeUndefined(newAppointment);

      const appointmentRef = await addDoc(
        collection(fireStoreDb, "appointments"),
        newAppointment
      );

      await updateDoc(doc(fireStoreDb, "appointments", appointmentRef.id), {
        id: appointmentRef.id,
      });

      const provider = params.provider;
      if (provider?.email) {
        await addDoc(collection(fireStoreDb, "mail"), {
          to: provider.email,
          message: {
            subject: isExamAppointment
              ? "Nova solicitação de exame"
              : "Nova solicitação de agendamento",
            html: generateEmailRequest(provider, newAppointment as Appointment),
          },
        } as EmailStructure);

        if (provider.id) {
          const sendNotification = httpsCallable(
            functions,
            "sendAppointmentNotification",
            { limitedUseAppCheckTokens: true }
          );
          console.log("there was providerId");

          sendNotification({
            appointmentId: appointmentRef.id,
            userId: provider.id,
            title: `Zip | ${
              isExamAppointment ? "Exame" : "Agendamento"
            } solicitado`,
            body: `Um paciente solicitou um ${
              isExamAppointment ? "exame" : "agendamento"
            }. Toque para ver mais informações.`,
          });
        }
      }

      return appointmentRef.id;
    },
    onSuccess: (appointmentId) => {
      queryClient.invalidateQueries({ queryKey: ["appointments"] });
      navigate(`/appointments/${appointmentId}`);
      toast.success("Agendamento marcado com sucesso!");
    },
    onError: (error) => {
      toast.error("Erro ao salvar sugestões!");
      console.error("Error adding document: ", error);
    },
  });
};
