import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { doc, updateDoc } from "firebase/firestore";
import { auth, fireStoreDb, messaging } from "../firebase/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

const VAPID_KEY =
  "BMcPCXQ2gMgn2YgSt2NmPepL2i5Lk1pIiJkTjfHynRnHlWPuRhGYmO5azgs9A32nXP2tZ10Utqm7zQuz5HWkzNE";

class NotificationService {
  public initialized = false;
  private _initializing = false;
  private navigate: NavigateFunction | null = null;
  private tokenStored = false;

  private async getCurrentUserId(): Promise<string | undefined> {
    if (Capacitor.isNativePlatform()) {
      const result = await FirebaseAuthentication.getCurrentUser();
      return result.user?.uid;
    }

    return auth.currentUser?.uid;
  }

  async initialize(navigate: NavigateFunction) {
    try {
      console.log("🔔 Starting notification service initialization...");

      if (this.initialized) {
        console.log("Service already initialized, skipping...");
        return;
      }

      if (this._initializing) {
        console.log("Service initialization already in progress, skipping...");
        return;
      }

      this._initializing = true;
      this.navigate = navigate;

      if (Capacitor.isNativePlatform()) {
        console.log("Setting up mobile notifications...");
        await this.setupMobileNotifications();
      } else {
        if (this.isPWA()) {
          console.log("Running as PWA...");
          await this.setupWebNotifications();
        } else {
          console.log("Setting up web notifications...");
          await this.setupWebNotifications();
        }
      }

      this.setupForegroundMessaging();

      // Mark initialized only if token was stored or if we're on web
      // For mobile, we rely on the token registration to complete
      if (!Capacitor.isNativePlatform() || this.tokenStored) {
        this.initialized = true;
        console.log("✅ Notification service initialization complete");
      } else {
        console.log(
          "⏳ Waiting for FCM token registration to complete initialization"
        );
        // Set a timeout to mark as initialized if token registration doesn't complete
        setTimeout(() => {
          if (!this.initialized) {
            console.warn(
              "⚠️ FCM token not received in time, marking service as initialized anyway"
            );
            this.initialized = true;
          }
        }, 10000);
      }
    } catch (error) {
      console.error(
        "❌ Error during notification service initialization:",
        error
      );
      this._initializing = false;
      throw error;
    }
  }

  public isPWA() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone
    );
  }

  private async setupMobileNotifications() {
    try {
      console.log("📱 Starting mobile notifications setup...");

      const result = await PushNotifications.requestPermissions();
      console.log("Permission request result:", result);

      if (result.receive === "granted") {
        // Register push notification listeners first
        this.registerPushListeners();

        // Register for push notifications
        await PushNotifications.register();
        console.log("✅ Push notifications registered");

        // For Android, we can try to get the FCM token directly
        // This is a fallback - the main path is through the registration event
        if (Capacitor.getPlatform() === "android") {
          try {
            const { token } = await FCM.getToken();
            console.log("📱 FCM token received directly:", token);
            if (token) {
              await this.storeMobileToken(token);
              this.tokenStored = true;
              console.log("✅ FCM token stored in Firestore directly");
            }
          } catch (fcmError) {
            console.error("Error getting FCM token directly:", fcmError);
            // We'll rely on the registration event instead
          }
        }
      } else {
        console.warn("⚠️ Push notification permission denied");
        // Even without permissions, we can mark as initialized
        this._initializing = false;
        this.initialized = true;
      }
    } catch (error) {
      console.error("❌ Error in setupMobileNotifications:", error);
      this._initializing = false;
      throw error;
    }
  }

  private registerPushListeners() {
    // Registration listener
    PushNotifications.addListener("registration", async (token) => {
      console.log("📝 Push registration success, token:", token.value);
      try {
        await this.storeMobileToken(token.value);
        this.tokenStored = true;
        console.log("✅ Token successfully stored in Firestore via listener");

        // Mark as initialized if we were waiting for token registration
        if (this._initializing && !this.initialized) {
          this.initialized = true;
          this._initializing = false;
          console.log(
            "✅ Notification service initialization complete (after token registration)"
          );
        }
      } catch (error) {
        console.error("❌ Error storing token:", error);
        // Mark as initialized even if token storage fails
        if (this._initializing && !this.initialized) {
          this.initialized = true;
          this._initializing = false;
        }
      }
    });

    // Notification received listener
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("📬 Push notification received:", notification);
        this.handleMobileNotification(notification);
      }
    );

    // Action performed listener
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        console.log("🎯 Notification action performed:", action);
        const notificationData = action.notification.data as { URL?: string };
        if (notificationData?.URL) {
          this.handleNotificationClick(notificationData.URL);
        }
      }
    );
  }

  private async setupWebNotifications() {
    try {
      const permission = await this.requestNotificationPermission();
      if (permission === "granted") {
        await this.getAndStoreWebToken();
        this.tokenStored = true;
      }
      // Mark as done with setup regardless of permission
      this._initializing = false;
    } catch (error) {
      console.error("Error setting up web notifications:", error);
      this._initializing = false;
    }
  }

  async requestNotificationPermission() {
    if (Capacitor.isNativePlatform()) return "granted";

    try {
      const permission = await Notification.requestPermission();
      return permission;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return "denied";
    }
  }

  private async getAndStoreWebToken() {
    try {
      const token = await getToken(messaging, { vapidKey: VAPID_KEY });
      console.log(token);

      if (token) {
        await this.storeUserToken(token);
        console.log("it was true");

        return true;
      }
      console.log("it was false");
      return false;
    } catch (error) {
      console.error("An error occurred while retrieving web token:", error);
      return false;
    }
  }

  private async storeUserToken(token: string) {
    try {
      const userId = await this.getCurrentUserId();
      if (!userId) {
        console.warn("⚠️ No user logged in, cannot store token");
        return false;
      }

      const userRef = doc(fireStoreDb, "users", userId);
      await updateDoc(userRef, {
        fcmToken: token,
        tokenUpdatedAt: new Date().toISOString(),
      });
      return true;
    } catch (error) {
      console.error("Error storing user token:", error);
      throw error;
    }
  }

  private async storeMobileToken(token: string) {
    try {
      console.log("💾 Attempting to store mobile token...");
      const userId = await this.getCurrentUserId();
      console.log("Current user:", userId);

      if (!userId) {
        console.warn("⚠️ No user logged in, cannot store token");
        return false;
      }

      const userRef = doc(fireStoreDb, "users", userId);
      await updateDoc(userRef, {
        fcmToken: token,
        tokenUpdatedAt: new Date().toISOString(),
        platform: Capacitor.getPlatform(),
      });
      console.log("✅ Token successfully stored for user:", userId);
      return true;
    } catch (error) {
      console.error("❌ Error storing mobile token:", error);
      throw error;
    }
  }

  private setupForegroundMessaging() {
    if (Capacitor.isNativePlatform()) {
      // For mobile, foreground notifications are handled by pushNotificationReceived
      return;
    }

    console.log("foreground received");

    onMessage(messaging, (payload: any) => {
      const { body, imageUrl, URL } = payload.data;
      console.log("foreground received!!!!");

      toast.info(
        <div
          onClick={() => this.handleNotificationClick(URL)}
          className="flex items-start gap-2 cursor-pointer"
        >
          <img
            src={imageUrl || "/images/icons/icon.png"}
            alt="Notification Icon"
            className="w-12 h-12 rounded-medium"
          />
          <p>{body}</p>
        </div>,
        {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: false,
        }
      );
    });
  }

  private handleMobileNotification(notification: any) {
    const { imageUrl, body, data } = notification;

    toast.info(
      <div
        onClick={() => this.handleNotificationClick(data.URL)}
        className="flex items-start gap-2 cursor-pointer"
      >
        <img
          src={imageUrl || "/images/icons/icon.png"}
          alt="Notification Icon"
          className="w-12 h-12 rounded-medium"
        />
        <p>{body}</p>
      </div>,
      {
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: false,
      }
    );
  }

  private handleNotificationClick(url: string) {
    if (this.navigate) {
      this.navigate(url);
    } else {
      console.error("Navigation function not set");
    }
  }

  // Add method to force token refresh
  public async refreshToken() {
    try {
      console.log("🔄 Forcing token refresh...");
      this.tokenStored = false;

      if (Capacitor.isNativePlatform()) {
        if (Capacitor.getPlatform() === "android") {
          try {
            const { token } = await FCM.getToken();
            if (token) {
              await this.storeMobileToken(token);
              this.tokenStored = true;
              console.log("✅ Refreshed FCM token stored in Firestore");
              return true;
            }
          } catch (error) {
            console.error("Error refreshing FCM token:", error);
          }
        }
        // For iOS or if Android FCM fails, try to re-register
        await PushNotifications.register();
        return true;
      } else {
        // Web
        return await this.getAndStoreWebToken();
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      return false;
    }
  }
}

export const notificationService = new NotificationService();
