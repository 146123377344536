import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export const ExpandableText = ({
  text,
  maxLength = 200,
}: {
  text: string;
  maxLength: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = text.length > maxLength;

  const truncatedText = text.slice(0, maxLength);
  const remainingText = text.slice(maxLength);

  return (
    <div className="text-sm font-medium text-default-700 px-0.5">
      <div className="text-justify">
        {truncatedText}
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.span
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                height: { duration: 0.3, ease: "easeOut" },
                opacity: { duration: 0.2, ease: "easeOut" },
              }}
            >
              {remainingText}
            </motion.span>
          )}
        </AnimatePresence>
        {shouldTruncate && !isExpanded && (
          <motion.span
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            className="inline-block"
          >
            ...
          </motion.span>
        )}
        {shouldTruncate && (
          <motion.button
            onClick={() => setIsExpanded(!isExpanded)}
            className="pl-1 text-primary hover:text-primary-600 font-semibold focus:outline-primary"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isExpanded ? "Mostrar Menos" : "Mostrar Mais"}
          </motion.button>
        )}
      </div>
    </div>
  );
};
