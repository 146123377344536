import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
  where,
  orderBy,
  limit,
  getCountFromServer,
  QueryDocumentSnapshot,
  DocumentData,
  startAfter,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { fireStoreDb, performance } from "../../services/firebase/firebase";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";
import { PaymentMethod } from "../../models/PaymentMethod";
import { parseCurrency } from "../../utils/currencyUtils";
import { trace } from "firebase/performance";

interface UseProviderQueryParams {
  stateId: string;
  cityId: string;
  specialtyId?: string;
  serviceId?: string;
  filterText?: string;
  paymentMethods?: PaymentMethod[];
  selectedHealthInsurance?: string;
  priceRange?: [number, number];
  format?: { online?: boolean; presential?: boolean } | null;
  page: number;
  pageSize: number;
}

const fetchProviders = async (
  relatedClinicId?: string,
  examId?: string
): Promise<ProviderInformation[]> => {
  let providerQuery = query(collection(fireStoreDb, "providers"));

  if (relatedClinicId) {
    providerQuery = query(
      collection(fireStoreDb, "providers"),
      where("relatedClinicId", "==", relatedClinicId)
    );
  }

  if (examId) {
    providerQuery = query(
      providerQuery,
      where("exams", "array-contains", examId)
    );
  }

  const querySnapshot = await getDocs(providerQuery);

  const providers = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {} as Clinic;
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
      } as ProviderInformation;
    })
  );

  // Sort providers by `averageRating`, placing those without a rating at the bottom
  return providers.sort((a, b) => {
    const ratingA = a.averageRating ?? -1; // Place providers without a rating last
    const ratingB = b.averageRating ?? -1;
    return ratingB - ratingA; // Sort descending by rating
  });
};

export const useProvidersQuery = (
  relatedClinicId?: string,
  examId?: string
) => {
  return useQuery<ProviderInformation[], Error>({
    queryKey: ["providers", "list", relatedClinicId, examId],
    queryFn: () => fetchProviders(relatedClinicId, examId),
    enabled: !!relatedClinicId || !!examId || (!relatedClinicId && !examId), // Enable query when conditions are met
  });
};

export const useSearchProvidersQuery = ({
  stateId,
  cityId,
  specialtyId,
  serviceId,
  filterText,
  paymentMethods,
  selectedHealthInsurance,
  priceRange,
  format,
  page,
  pageSize,
}: UseProviderQueryParams) => {
  return useQuery<
    { providers: ProviderInformation[]; totalCount: number },
    Error
  >({
    queryKey: [
      "providers",
      {
        stateId,
        cityId,
        specialtyId,
        serviceId,
        filterText,
        paymentMethods,
        selectedHealthInsurance,
        priceRange,
        format,
        page,
        pageSize,
      },
    ],
    queryFn: async () => {
      const providerTrace = trace(performance, "fetch-providers");
      providerTrace.start();

      try {
        // Initialize base query with essential filters
        let baseQuery = query(
          collection(fireStoreDb, "providers"),
          where("relatedClinic.state.id", "==", stateId),
          where("relatedClinic.city.id", "==", cityId),
          where("status", "==", "active"),
          orderBy("name")
        );

        if (specialtyId) {
          baseQuery = query(
            baseQuery,
            where("specialties", "array-contains", specialtyId)
          );
        }

        if (paymentMethods && paymentMethods.length > 0) {
          baseQuery = query(
            baseQuery,
            where(
              "acceptedPaymentMethods",
              "array-contains-any",
              paymentMethods
            )
          );
        }

        if (filterText) {
          const filterTextLower = filterText.toLowerCase();
          baseQuery = query(
            baseQuery,
            where("name", ">=", filterTextLower),
            where("name", "<=", `${filterTextLower}\uf8ff`)
          );
        }

        // Get total count
        const countSnapshot = await getCountFromServer(baseQuery);
        const totalCount = countSnapshot.data().count;

        // Get the initial data for the current page
        let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

        // If not the first page, we need to get the document to start after
        if (page > 1) {
          // Get all documents up to the start of our page
          const previousPageQuery = query(
            baseQuery,
            limit((page - 1) * pageSize)
          );
          const previousPageSnapshot = await getDocs(previousPageQuery);
          lastVisible =
            previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
        }

        // Construct the query for the current page
        const paginatedQuery = lastVisible
          ? query(baseQuery, startAfter(lastVisible), limit(pageSize))
          : query(baseQuery, limit(pageSize));

        const providersSnapshot = await getDocs(paginatedQuery);

        const providers = providersSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as ProviderInformation[];

        // Apply client-side filters
        const filteredProviders = providers.filter((provider) => {
          if (serviceId && provider.schedules?.appointments?.services) {
            const hasService = Object.values(
              provider.schedules.appointments.services
            ).some((service) => {
              const hasMatchingService =
                service.name?.toLowerCase() === serviceId.toLowerCase();
              const hasMatchingInsurance =
                !selectedHealthInsurance ||
                service.acceptedHealthInsurances?.includes(
                  selectedHealthInsurance
                );
              const price = parseCurrency(service.price as string);
              const isInPriceRange =
                !priceRange ||
                (price >= priceRange[0] && price <= priceRange[1]);

              const hasMatchingFormat =
                !format ||
                (!format.online && !format.presential) || // If no format is selected
                (format.online && service.format?.online) || // If online is selected and service is online
                (format.presential && service.format?.presential);
              return (
                hasMatchingService &&
                hasMatchingInsurance &&
                isInPriceRange &&
                hasMatchingFormat
              );
            });
            return hasService;
          }
          return true;
        });

        return {
          providers: filteredProviders,
          totalCount,
        };
      } catch (error) {
        console.error("Error fetching providers:", error);
        throw error;
      } finally {
        providerTrace.stop();
      }
    },
    enabled: !!(specialtyId || serviceId),
  });
};

export const useUpdateProvider = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (providerDetails: ProviderInformation) => {
      // Input validation
      if (!providerDetails.id) {
        throw new Error("Provider ID is required");
      }

      // Get the current provider to compare if name changed
      const prevProviderDoc = await getDoc(
        docRef(fireStoreDb, "providers", providerDetails.id)
      );
      const prevProvider = prevProviderDoc.data() as ProviderInformation;
      console.log(prevProvider);

      // Check if name changed
      const nameChanged =
        prevProvider && prevProvider.name !== providerDetails.name;
      console.log(prevProvider.name, providerDetails.name);

      // Perform the document update
      await setDoc(
        doc(fireStoreDb, "providers", providerDetails.id),
        providerDetails,
        { merge: true }
      );

      // If name changed, update associated user
      if (nameChanged && providerDetails.name) {
        const usersRef = collection(fireStoreDb, "users");
        const q = query(
          usersRef,
          where("providerId", "==", providerDetails.id)
        );
        const querySnapshot = await getDocs(q);

        // Process each matching user document
        const updatePromises = querySnapshot.docs.map((doc) => {
          return updateDoc(doc.ref, {
            name: providerDetails.name,
          });
        });

        await Promise.all(updatePromises);
      }

      return providerDetails;
    },
    onSuccess: (updatedProvider) => {
      // Invalidate relevant queries to trigger refetch
      queryClient.invalidateQueries({
        queryKey: ["providers"],
      });

      // Optional: Update the specific provider in the cache
      queryClient.setQueryData(
        ["providers", "list", updatedProvider.relatedClinicId],
        (oldData: ProviderInformation[] | undefined) => {
          if (!oldData) return undefined;

          return oldData.map((provider) =>
            provider.id === updatedProvider.id
              ? { ...provider, ...updatedProvider }
              : provider
          );
        }
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
