import { Building2, MapPin, Phone } from "lucide-react";
import { BookingInfo } from "../../Atoms/BookingInfo";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { PaymentMethodsList } from "../PaymentMethodsList";
import { formatPhoneNumber } from "../../../utils/phoneNumberUtils";

export const ContactInfoGrid = ({
  phoneNumber,
  name,
  address,
  methods,
}: {
  phoneNumber: string;
  name: string;
  address: string;
  timezone: string;
  userTimezone: string;
  methods: PaymentMethod[];
}) => {
  return (
    <div className="flex flex-col gap-2">
      <BookingInfo
        icon={Building2}
        text={name}
        iconColor="text-primary-600"
        textColor="text-primary-800"
      />
      <BookingInfo
        icon={MapPin}
        text={address}
        iconColor="text-secondary-600"
        textColor="text-secondary-800"
        className="col-span-2"
      />
      <BookingInfo
        icon={Phone}
        text={formatPhoneNumber("+55" + phoneNumber)}
        iconColor="text-warning-600"
        textColor="text-warning-800"
      />

      <PaymentMethodsList methods={methods} />

      {/* {timezone !== userTimezone && (
      <BookingInfo
        icon={Clock}
        text={`Fuso-horário: ${timezone}`}
        iconColor="text-danger-600"
        textColor="text-danger-800"
        className="col-span-2"
      />
    )} */}
    </div>
  );
};
