import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  where,
  orderBy,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  DocumentData,
  setDoc,
  doc,
} from "firebase/firestore";
import { fireStoreDb, performance } from "../../services/firebase/firebase";
import { Clinic } from "../../models/Clinic";
import { PaymentMethod } from "../../models/PaymentMethod";
import { parseCurrency } from "../../utils/currencyUtils";
import { trace } from "firebase/performance";

interface UseClinicQueryParams {
  stateId?: string;
  cityId?: string;
  examId?: string;
  filterText?: string;
  paymentMethods?: PaymentMethod[];
  selectedHealthInsurance?: string;
  priceRange?: [number, number];
  page: number;
  pageSize: number;
}

const fetchClinics = async (): Promise<Clinic[]> => {
  const clinicQuery = query(collection(fireStoreDb, "clinics"));
  const querySnapshot = await getDocs(clinicQuery);

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    timezone: "America/Sao_Paulo",
  })) as Clinic[];
};

export const useClinicsQuery = () => {
  return useQuery<Clinic[], Error>({
    queryKey: ["clinics", "list"],
    queryFn: () => fetchClinics(),
  });
};

export const useSearchClinicsQuery = ({
  stateId,
  cityId,
  examId,
  filterText,
  paymentMethods,
  selectedHealthInsurance,
  priceRange,
  page,
  pageSize,
}: UseClinicQueryParams) => {
  return useQuery<{ clinics: Clinic[]; totalCount: number }, Error>({
    queryKey: [
      "clinics",
      {
        stateId,
        cityId,
        examId,
        filterText,
        paymentMethods,
        selectedHealthInsurance,
        priceRange,
        page,
        pageSize,
      },
    ],
    queryFn: async () => {
      const clinicTrace = trace(performance, "fetch-clinics");
      clinicTrace.start();

      try {
        // Base query with filters
        let baseQuery = query(
          collection(fireStoreDb, "clinics"),
          where("status", "==", "active"),
          orderBy("name")
        );

        if (stateId) {
          baseQuery = query(baseQuery, where("state.id", "==", stateId));
        }
        if (cityId) {
          baseQuery = query(baseQuery, where("city.id", "==", cityId));
        }
        if (paymentMethods && paymentMethods.length > 0) {
          baseQuery = query(
            baseQuery,
            where(
              "acceptedPaymentMethods",
              "array-contains-any",
              paymentMethods
            )
          );
        }
        if (filterText) {
          baseQuery = query(
            baseQuery,
            where("name", ">=", filterText.toLowerCase()),
            where("name", "<=", `${filterText.toLowerCase()}\uf8ff`)
          );
        }

        // Get total count
        const countSnapshot = await getDocs(baseQuery);
        const totalCount = countSnapshot.size;

        // Get the last visible document for pagination
        let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

        // If not the first page, get the document to start after
        if (page > 1) {
          const previousPageQuery = query(
            baseQuery,
            limit((page - 1) * pageSize)
          );
          const previousPageSnapshot = await getDocs(previousPageQuery);
          lastVisible =
            previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
        }

        // Construct the query for the current page
        const paginatedQuery = lastVisible
          ? query(baseQuery, startAfter(lastVisible), limit(pageSize))
          : query(baseQuery, limit(pageSize));

        const clinicsSnapshot = await getDocs(paginatedQuery);

        // Map clinics from snapshot
        const clinics = clinicsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          timezone: "America/Sao_Paulo",
        })) as Clinic[];

        // Filter clinics based on exams and price range
        const filteredClinics = clinics.filter((clinic) => {
          if (examId && clinic.schedules?.exams?.exams) {
            const hasExam = Object.values(clinic.schedules.exams.exams).some(
              (exam) => {
                const hasMatchingExam =
                  exam.name?.toLowerCase() === examId.toLowerCase();
                const hasMatchingInsurance =
                  !selectedHealthInsurance ||
                  exam.acceptedHealthInsurances?.includes(
                    selectedHealthInsurance
                  );
                const price = parseCurrency(exam.price as string);
                const isInPriceRange =
                  !priceRange ||
                  (price >= priceRange[0] && price <= priceRange[1]);
                return (
                  hasMatchingExam && hasMatchingInsurance && isInPriceRange
                );
              }
            );
            return hasExam;
          }
          return true;
        });

        return {
          clinics: filteredClinics,
          totalCount,
        };
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        clinicTrace.stop();
      }
    },
    enabled: !!(stateId || cityId || examId || filterText),
  });
};

export const useUpdateClinic = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clinicDetails: Clinic) => {
      // Input validation
      if (!clinicDetails.id) {
        throw new Error("Clinic ID is required");
      }

      // Perform the document update
      await setDoc(
        doc(fireStoreDb, "clinics", clinicDetails.id),
        clinicDetails,
        { merge: true }
      );

      return clinicDetails;
    },
    onSuccess: (updatedClinic) => {
      // Invalidate relevant queries to trigger refetch
      queryClient.invalidateQueries({
        queryKey: ["clinics"],
      });

      // Optional: Update the specific provider in the cache
      queryClient.setQueryData(
        ["clinics", "list", updatedClinic.id],
        (oldData: Clinic[] | undefined) => {
          if (!oldData) return undefined;

          return oldData.map((provider) =>
            provider.id === updatedClinic.id
              ? { ...provider, ...updatedClinic }
              : provider
          );
        }
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export const useAddClinic = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clinicDetails: Clinic) => {
      // add new entries validation after meet
      if (!clinicDetails.name || !clinicDetails.name.trim()) {
        throw new Error("Nome inválido");
      }

      if (!clinicDetails.email || !clinicDetails.email.trim()) {
        throw new Error("E-mail inválido");
      }

      const docRef = doc(collection(fireStoreDb, "clinics"));
      await setDoc(docRef, clinicDetails);

      return clinicDetails;
    },
    onSuccess: () => {
      // Invalidate relevant queries to trigger refetch
      queryClient.invalidateQueries({
        queryKey: ["clinics"],
      });
    },
    onError: (error) => {
      console.error(`Mutation error:`, error);
    },
  });
};
