import { ProviderInformation } from "../../../models/ProviderInformation";
import { Clinic } from "../../../models/Clinic";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";

export const EntityInfo = ({
  provider,
  clinic,
  titleClassName,
  descriptionClassName,
  avatarClassName,
}: {
  provider?: ProviderInformation;
  clinic?: Clinic;
  titleClassName?: string;
  descriptionClassName?: string;
  avatarClassName?: string;
}) => (
  <div className="flex gap-2">
    <FirebaseAvatar
      imageUrl={clinic ? clinic.imageUrl || "" : provider?.imageUrl || ""}
      name={clinic ? clinic.name || "" : provider?.name || ""}
      className={avatarClassName}
      radius="md"
      size="md"
    />
    <div className="flex flex-col py-0.5">
      <span
        className={titleClassName || "text-sm font-medium text-default-900"}
      >
        {clinic ? clinic.name : provider?.name}
      </span>
      <span
        className={
          descriptionClassName || "text-sm font-medium text-default-400"
        }
      >
        {clinic
          ? clinic.cnpj
          : `${provider?.professionalCouncil.conselho}: ${provider?.professionalCouncil.numero} - ${provider?.professionalCouncil.estado}`}
      </span>
    </div>
  </div>
);
