import React, { useState, useCallback } from "react";
import { Button, Divider, Spinner, useDisclosure } from "@nextui-org/react";

import { UserInfo } from "../../Molecules/UserInfo";
import { SearchProvider } from "../../Molecules/SearchProvider";
import { ProvidersList } from "../../Organisms/ProvidersList";
import { ClinicsList } from "../../Organisms/ClinicsList";
import { useProviderSearch } from "../../../hooks/useProviderSearch";
import { useUserInformationQuery } from "../../../hooks/queries/userInformationQuery";
import { compareStrings } from "../../../utils/compareStrings";
import { EmptyResultCard } from "../../Molecules/EmptyResultCard";
import { FiltersModal } from "../../Organisms/FiltersModal";
import { Filter } from "lucide-react";
import { PaymentMethod } from "../../../models/PaymentMethod";

export const Home: React.FC = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const {
    selectedState,
    selectedCity,
    selectedMedicalService,
    filterText,
    selectedHealthInsurance,
    healthInsurances,
    priceRange,
    providers,
    totalProviders,
    totalClinics,
    clinics,
    stateItems,
    cityItems,
    medicalServiceItems,
    isLoadingStates,
    isLoadingCities,
    isLoadingMedicalServices,
    isLoadingProviders,
    isLoadingClinics,
    locationLoading,
    format,
    page,
    pageSize,
    handleStateChange,
    handleCityChange,
    handleMedicalServiceChange,
    handleSearch: executeSearch,
    handleFilterChange,
    handleLocationSearch,
    handlePageChange,
    isSpecialtySearch,
  } = useProviderSearch();

  const { data: userInfo, isLoading: isLoadingUserInfo } =
    useUserInformationQuery();

  const handleSearch = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      executeSearch(e);
      setHasSearched(true);
    },
    [executeSearch]
  );

  const handleStateChangeWrapper = useCallback(
    (stateId: string | null) => {
      handleStateChange(stateId);
      setHasSearched(false);
    },
    [handleStateChange]
  );

  const handleCityChangeWrapper = useCallback(
    (cityId: string | null) => {
      handleCityChange(cityId);
      setHasSearched(false);
    },
    [handleCityChange]
  );

  const handleMedicalServiceChangeWrapper = useCallback(
    (medicalServiceId: string | null) => {
      handleMedicalServiceChange(medicalServiceId);
      setHasSearched(false);
    },
    [handleMedicalServiceChange]
  );

  const handleApplyFilter = useCallback(
    (
      text: string,
      paymentMethods: PaymentMethod[],
      healthInsurance: string,
      newPriceRange: [number, number],
      format: { online?: boolean; presential?: boolean } | null
    ) => {
      handleFilterChange(
        text,
        paymentMethods,
        healthInsurance,
        newPriceRange,
        format
      );
    },
    [handleFilterChange]
  );

  const renderSearchResults = () => {
    if (isLoadingProviders || isLoadingClinics) {
      return <Spinner color="primary" label="Carregando Resultados..." />;
    }

    if (
      selectedMedicalService?.type === "specialty" ||
      selectedMedicalService?.type === "service"
    ) {
      return providers && providers.length > 0 ? (
        <ProvidersList
          providers={providers}
          totalProviders={totalProviders}
          page={page}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      ) : (
        <EmptyResultCard
          medicalService={selectedMedicalService}
          onPress={() => handleMedicalServiceChangeWrapper("")}
        />
      );
    } else if (selectedMedicalService?.type === "exam") {
      return clinics && clinics.length > 0 ? (
        <ClinicsList
          clinics={clinics}
          totalClinics={totalClinics}
          page={page}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          examId={selectedMedicalService.id}
        />
      ) : (
        <EmptyResultCard
          medicalService={selectedMedicalService}
          onPress={() => handleMedicalServiceChangeWrapper("")}
        />
      );
    }
    return null;
  };

  return (
    <main className="flex flex-col justify-center gap-8 items-center min-h-svh p-4 w-full">
      <UserInfo
        userInfo={userInfo}
        description="Bem-vindo(a) ao painel da Zip!"
        isLoading={isLoadingUserInfo}
      />

      <div className="flex flex-col justify-center gap-4 max-md:w-full">
        <span className="text-xl font-medium text-center">
          {hasSearched && selectedMedicalService
            ? `${selectedMedicalService.id} perto de mim`
            : "Agende uma consulta ou um exame"}
        </span>

        <div className="flex flex-col gap-4">
          <SearchProvider
            selectedState={selectedState}
            selectedCity={selectedCity}
            selectedMedicalService={selectedMedicalService}
            stateItems={stateItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            cityItems={cityItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            medicalServiceItems={medicalServiceItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            onStateChange={handleStateChangeWrapper}
            onCityChange={handleCityChangeWrapper}
            onMedicalServiceChange={handleMedicalServiceChangeWrapper}
            onLocationSearch={handleLocationSearch}
            onSearch={handleSearch}
            isDisabled={
              !selectedState || !selectedCity || !selectedMedicalService
            }
            isLoadingStates={isLoadingStates}
            isLoadingCities={isLoadingCities}
            isLoadingMedicalServices={isLoadingMedicalServices}
            locationSearchLoading={locationLoading}
          />

          {hasSearched && (
            <>
              <Divider className="bg-default-300 h-1 rounded-full" />

              <Button
                onPress={onOpen}
                color="warning"
                variant="flat"
                className="font-medium bg-warning-50 text-warning"
                startContent={<Filter className="max-md:w-5 w-4" />}
              >
                Filtrar Resultados
              </Button>

              {renderSearchResults()}
            </>
          )}
        </div>
      </div>

      <FiltersModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onApplyFilter={handleApplyFilter}
        initialFilterText={filterText}
        initialSelectedHealthInsurance={selectedHealthInsurance}
        initialPriceRange={priceRange}
        initialFormat={format}
        healthInsurances={healthInsurances || []}
        isSpecialtySearch={isSpecialtySearch}
      />
    </main>
  );
};
