import React, { useState, useEffect, useRef, Ref } from "react";
import {
  motion,
  AnimatePresence,
  useDragControls,
  Reorder,
} from "framer-motion";
import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@nextui-org/react";
import {
  Check,
  CheckCircle2,
  GripVertical,
  Pencil,
  Trash2,
  X,
} from "lucide-react";
import { getPlatformDetails } from "../../../utils/platformUtils";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireStoreDb } from "../../../services/firebase/firebase";
import { ContactMethod } from "../../../models/ContactMethod";
import { toast } from "react-toastify";
import { PhoneNumberInput, GoogleMapsInput } from "../Inputs";

interface ContactMethodCardProps {
  contactMethod: ContactMethod;
  providerId: string;
  onSuccess: () => void;
  currentRedirectMethods:
    | {
        whatsApp: boolean;
        zipSaude: boolean;
      }
    | undefined;
}

const cardVariants = {
  initial: {
    opacity: 0,
    y: 20,
    scale: 0.95,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      delayChildren: 0.1,
      staggerChildren: 0.05,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: -20,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  drag: {
    scale: 1.03,
    cursor: "grabbing",
  },
};

const contentVariants = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25,
    },
  },
};

const successAnimation = {
  scale: [1, 1.2, 1],
  rotate: [0, 360, 360],
  transition: {
    duration: 0.8,
    ease: "easeInOut",
  },
};

export const ContactMethodCard: React.FC<ContactMethodCardProps> = ({
  contactMethod,
  providerId,
  onSuccess,
  currentRedirectMethods,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedValue, setEditedValue] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const dragHandlerRef = useRef<HTMLElement | null>(null);
  const dragControls = useDragControls();

  const platformConfig = getPlatformDetails(contactMethod.platform);
  const { Icon } = platformConfig;
  const isWhatsApp = contactMethod.platform === "whatsapp";
  const isGoogleMaps = contactMethod.platform === "google-maps";

  useEffect(() => {
    if (isEditing) {
      if (isWhatsApp) {
        const number = contactMethod.link.match(/wa\.me\/55(\d+)/)?.[1] || "";
        setEditedValue(number);
      } else if (isGoogleMaps) {
        // For Google Maps, we'll use the place ID or full link
        setEditedValue(contactMethod.link);
      } else {
        setEditedValue(
          getDisplayLink(contactMethod.link, contactMethod.platform)
        );
      }
    }
  }, [isEditing, contactMethod.link, contactMethod.platform]);

  useEffect(() => {
    const touchHandler: React.TouchEventHandler<HTMLElement> = (e) =>
      e.preventDefault();

    const dragHandlerTag = dragHandlerRef.current;

    if (dragHandlerTag) {
      //@ts-ignore
      dragHandlerTag.addEventListener("touchstart", touchHandler, {
        passive: false,
      });

      return () => {
        //@ts-ignore
        dragHandlerTag.removeEventListener("touchstart", touchHandler, {
          passive: false,
        });
      };
    }
  }, [dragHandlerRef]);

  const getDisplayLink = (fullLink: string, platform: string) => {
    const platformConfig = getPlatformDetails(platform);
    return platformConfig.getValueFromLink?.(fullLink) || fullLink;
  };

  const validateInput = (value: string) => {
    if (!value.trim()) {
      setInputError("Campo obrigatório");
      return false;
    }

    if (platformConfig.validate && !platformConfig.validate(value)) {
      setInputError(`Formato inválido para ${platformConfig.label}`);
      return false;
    }

    setInputError("");
    return true;
  };

  const formatLink = (value: string) => {
    const platformConfig = getPlatformDetails(contactMethod.platform);
    return platformConfig.getLinkFromValue?.(value) || value;
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedValue("");
    setInputError("");
  };

  const handleValueChange = (value: string) => {
    setEditedValue(value);
    validateInput(value);
  };

  const handleMapsPlaceSelect = (placeId: string) => {
    setEditedValue(placeId);
    setInputError("");
  };

  const handleUpdate = async () => {
    setIsSaving(true);
    try {
      const providerRef = doc(fireStoreDb, "zipBios", providerId);
      const providerDoc = await getDoc(providerRef);
      const currentMethods = providerDoc.data()?.contactMethods || [];

      const updatedMethods = currentMethods.map((method: ContactMethod) =>
        method.id === contactMethod.id
          ? { ...method, link: formatLink(editedValue) }
          : method
      );

      await updateDoc(providerRef, {
        contactMethods: updatedMethods,
      });

      setShowSuccess(true);
      await new Promise((resolve) => setTimeout(resolve, 1500));

      setShowSuccess(false);
      onSuccess();
      setIsEditing(false);
      toast.success("Método de contato atualizado com sucesso!");
    } catch (error) {
      console.error("Error updating contact method:", error);
      toast.error("Erro ao atualizar método de contato.");
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      const providerRef = doc(fireStoreDb, "zipBios", providerId);
      const providerDoc = await getDoc(providerRef);
      const currentMethods = providerDoc.data()?.contactMethods || [];

      const updatedMethods = currentMethods.filter(
        (method: ContactMethod) => method.id !== contactMethod.id
      );

      if (isWhatsApp) {
        await updateDoc(providerRef, {
          contactMethods: updatedMethods,
          redirectMethods: {
            ...currentRedirectMethods,
            whatsApp: false,
          },
        });
      } else {
        await updateDoc(providerRef, {
          contactMethods: updatedMethods,
        });
      }

      onSuccess();
      toast.success("Método de contato removido com sucesso!");
    } catch (error) {
      console.error("Error removing contact method:", error);
      toast.error("Erro ao remover método de contato.");
    }
  };

  const renderInput = () => {
    if (isWhatsApp) {
      return (
        <PhoneNumberInput
          size="lg"
          radius="md"
          variant="flat"
          label={platformConfig.label}
          value={
            isEditing
              ? editedValue
              : getDisplayLink(contactMethod.link, contactMethod.platform)
          }
          onChange={handleValueChange}
          isReadOnly={!isEditing}
          isInvalid={!!inputError}
          errorMessage={inputError}
          className="w-full"
        />
      );
    }

    if (isGoogleMaps) {
      return isEditing ? (
        <GoogleMapsInput
          size="lg"
          radius="md"
          variant="flat"
          label="Localização"
          placeholder="Pesquisar endereço..."
          inputValue={
            isEditing
              ? editedValue
              : getDisplayLink(contactMethod.link, contactMethod.platform)
          }
          onPlaceSelect={handleMapsPlaceSelect}
          isInvalid={!!inputError}
          errorMessage={inputError}
        />
      ) : (
        <Input
          size="lg"
          radius="md"
          variant="flat"
          label="Localização"
          value={
            getDisplayLink(contactMethod.link, contactMethod.platform) || ""
          }
          isReadOnly
        />
      );
    }

    return (
      <Input
        size="lg"
        radius="md"
        variant="flat"
        label={platformConfig.label}
        placeholder={platformConfig.placeholder}
        value={
          isEditing
            ? editedValue
            : getDisplayLink(contactMethod.link, contactMethod.platform)
        }
        isReadOnly={!isEditing}
        startContent={
          platformConfig.prefix || contactMethod.platform === "website" ? (
            <div className="pointer-events-none flex items-center">
              <span className="text-default-400 text-small">
                {contactMethod.platform === "website"
                  ? "https://"
                  : platformConfig.prefix}
              </span>
            </div>
          ) : null
        }
        onValueChange={handleValueChange}
        isInvalid={!!inputError}
        errorMessage={inputError}
      />
    );
  };

  return (
    <Reorder.Item
      key={contactMethod.id}
      value={contactMethod}
      className="w-full touch-none"
      dragListener={false}
      dragControls={dragControls}
      initial={false}
      whileDrag="drag"
      variants={cardVariants}
    >
      <Card shadow="none" radius="lg" className="p-0 w-full">
        <CardBody className="flex flex-col items-center gap-4 p-2 relative">
          <motion.div
            variants={contentVariants}
            className="w-full flex justify-between items-start"
          >
            <div className="flex gap-2 items-center">
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={platformConfig.color}
              >
                <Icon size="lg" />
              </motion.div>
              <span className="text-default-900 font-semibold">
                {platformConfig.label}
              </span>
            </div>

            <div className="flex gap-2">
              <AnimatePresence mode="wait">
                {isEditing ? (
                  <>
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                    >
                      <Button
                        isIconOnly
                        aria-label="Cancelar"
                        variant="flat"
                        color="danger"
                        radius="full"
                        size="sm"
                        onPress={handleCancel}
                      >
                        <X size={16} className="stroke-[3]" />
                      </Button>
                    </motion.div>
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                    >
                      <Button
                        isIconOnly
                        aria-label="Salvar"
                        variant="flat"
                        color="success"
                        radius="full"
                        size="sm"
                        isLoading={isSaving}
                        onPress={handleUpdate}
                        isDisabled={!editedValue || !!inputError}
                      >
                        <Check size={16} className="stroke-[3]" />
                      </Button>
                    </motion.div>
                  </>
                ) : (
                  <>
                    <Button
                      isIconOnly
                      variant="flat"
                      radius="full"
                      size="sm"
                      className="cursor-grab active:cursor-grabbing group"
                      onPointerDown={(e) => dragControls.start(e)}
                      ref={
                        dragHandlerRef as
                          | Ref<HTMLButtonElement | null>
                          | undefined
                      }
                    >
                      <GripVertical size={16} />
                    </Button>

                    <Button
                      isIconOnly
                      aria-label="Editar"
                      variant="flat"
                      color="default"
                      radius="full"
                      size="sm"
                      onPress={() => setIsEditing(true)}
                    >
                      <Pencil size={16} className="stroke-[3]" />
                    </Button>
                    <Popover
                      isOpen={isPopoverOpen}
                      onOpenChange={setIsPopoverOpen}
                      placement="bottom-end"
                      radius="lg"
                      backdrop="blur"
                      showArrow
                    >
                      <PopoverTrigger>
                        <Button
                          isIconOnly
                          aria-label="Excluir"
                          variant="flat"
                          color="default"
                          radius="full"
                          size="sm"
                          className="bg-default-900/50"
                        >
                          <Trash2
                            size={16}
                            className="text-default-100 stroke-[3]"
                          />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="flex flex-col gap-3 p-2 items-start max-w-60">
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          className="flex flex-col gap-2"
                        >
                          <span className="text-small font-semibold">
                            Confirmar exclusão
                          </span>
                          <p className="text-tiny text-default-600">
                            Tem certeza que deseja excluir este método de
                            contato?
                          </p>
                          <div className="flex justify-end gap-2">
                            <Button
                              size="sm"
                              radius="md"
                              variant="flat"
                              onPress={() => setIsPopoverOpen(false)}
                            >
                              Cancelar
                            </Button>
                            <Button
                              size="sm"
                              variant="flat"
                              radius="md"
                              color="danger"
                              onPress={handleDelete}
                            >
                              Excluir
                            </Button>
                          </div>
                        </motion.div>
                      </PopoverContent>
                    </Popover>
                  </>
                )}
              </AnimatePresence>
            </div>
          </motion.div>

          <motion.div
            variants={contentVariants}
            className="w-full flex flex-col gap-1"
          >
            <AnimatePresence>
              {isEditing && platformConfig.helperText && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  className="w-full px-1"
                >
                  <p className="text-xs text-default-500 text-justify">
                    Dica: {platformConfig.helperText}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
            {renderInput()}
          </motion.div>

          <AnimatePresence>
            {showSuccess && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 overflow-hidden flex items-center justify-center bg-success/10 backdrop-blur-[8px] z-20"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={successAnimation}
                  className="flex items-center justify-center"
                >
                  <CheckCircle2 className="text-success" size={48} />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </Reorder.Item>
  );
};
