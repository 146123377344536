import { forwardRef, useEffect, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { Chip, Skeleton } from "@nextui-org/react";
import { getFormattedAppointmentDate } from "../../../utils/getFormattedAppointmentDate";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { AppointmentCard } from "../../Molecules/AppointmentCard";
import { EmptyAppointmentCard } from "../../Molecules/EmptyAppointmentCard";
import { useSwipeable } from "react-swipeable";
import { statusToTabMap } from "../../../utils/statusTabMapping";
import { Clinic } from "../../../models/Clinic";
import { UserInformation } from "../../../models/UserInformation";
import { useQueryClient } from "@tanstack/react-query";

interface AppointmentsListProps {
  appointments: Appointment[];
  providers: ProviderInformation[];
  clinics: Clinic[];
  currentUser: AuthContextUserProps;
  users: (UserInformation & { id: string })[];
  isLoading: boolean;
  isLoadingProviders: boolean;
  isFetchingNextPage: boolean;
  handleSwipe: (status: AppointmentStatus) => void;
  selectedStatus: AppointmentStatus;
  isCustomFilterActive: boolean;
  ref: (node?: Element | null | undefined) => void;
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      when: "beforeChildren",
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

export const AppointmentsList = forwardRef<
  HTMLDivElement,
  AppointmentsListProps
>(
  (
    {
      appointments,
      providers,
      clinics,
      currentUser,
      users,
      isLoading,
      isLoadingProviders,
      isFetchingNextPage,
      handleSwipe,
      selectedStatus,
      isCustomFilterActive,
    },
    ref
  ) => {
    const queryClient = useQueryClient();
    const animationKey = selectedStatus;

    useEffect(() => {
      // Refetch appointments when the component mounts or when the selected status changes
      queryClient.invalidateQueries({ queryKey: ["appointments"] });
    }, [queryClient, selectedStatus]);

    const handlers = useSwipeable({
      onSwipedLeft: () => handleSwipeDirection("left"),
      onSwipedRight: () => handleSwipeDirection("right"),
      trackMouse: true,
    });

    const handleSwipeDirection = (direction: "left" | "right") => {
      const statusList = Object.keys(statusToTabMap) as AppointmentStatus[];
      const currentIndex = statusList.indexOf(selectedStatus);
      if (direction === "left" && currentIndex < statusList.length - 1) {
        handleSwipe(statusList[currentIndex + 1]);
      } else if (direction === "right" && currentIndex > 0) {
        handleSwipe(statusList[currentIndex - 1]);
      }
    };

    const appointmentCards = useMemo(() => {
      return appointments.map((appointment, index) => {
        const { formattedDate, formattedTime } = getFormattedAppointmentDate(
          appointment,
          currentUser.timezone
        );
        const provider = providers?.find(
          (p) => p.id === appointment.providerId
        );
        const clinic = clinics?.find((c) => c.id === appointment.clinicId);

        const commonProps = {
          appointment: appointment,
          provider: provider,
          examId: appointment.examId,
          formattedDate: formattedDate,
          formattedTime: formattedTime,
        };

        const isLastItem = index === appointments.length - 1;

        if (currentUser.role === "provider") {
          const user = users.find((q) => q?.id === appointment.userId);

          return (
            <motion.div
              key={appointment.id}
              ref={isLastItem ? ref : null}
              variants={itemVariants}
              layout
            >
              <AppointmentCard
                {...commonProps}
                user={user}
                clinic={!provider ? clinic : undefined}
                to={`/appointments/${appointment.id}`}
              />
            </motion.div>
          );
        }

        return (
          <motion.div
            key={appointment.id}
            ref={isLastItem ? ref : null}
            variants={itemVariants}
            layout
          >
            <AppointmentCard
              {...commonProps}
              clinic={!provider ? clinic : undefined}
              to={`/appointments/${appointment.id}`}
            />
          </motion.div>
        );
      });
    }, [appointments, providers, clinics, currentUser, users, ref]);

    if (isLoading || isLoadingProviders) {
      return (
        <div className="flex flex-col w-full gap-2" {...handlers}>
          {isCustomFilterActive && (
            <Skeleton className="bg-success-50 rounded-large h-7 px-0.5" />
          )}
          <Skeleton className="min-h-20 rounded-large h-40" />
          <Skeleton className="min-h-20 rounded-large h-40" />
          <Skeleton className="min-h-20 rounded-large h-40" />
        </div>
      );
    }

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={animationKey}
          {...handlers}
          className="flex flex-col w-full min-h-svh pb-20 gap-2"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {isCustomFilterActive && (
            <Chip
              color="success"
              variant="flat"
              className="w-full max-w-none text-center"
            >
              Filtro personalizado ativado
            </Chip>
          )}
          {appointments.length > 0 ? (
            <motion.div
              className="flex flex-col w-full gap-2"
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              {appointmentCards}
              {isFetchingNextPage && (
                <>
                  <Skeleton className="min-h-20 rounded-large h-40" />
                  <Skeleton className="min-h-20 rounded-large h-40" />
                </>
              )}
            </motion.div>
          ) : (
            <motion.div variants={itemVariants}>
              <EmptyAppointmentCard />
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
);
