import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { Home, Mail, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { WhatsAppIcon } from "../../Atoms/Icons";

export const Error = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: () => void;
}) => {
  const navigate = useNavigate();

  const handleReload = () => {
    window.location.reload();
  };

  const handleReturn = () => {
    resetErrorBoundary();
    navigate("/");
  };

  const handleSendEmail = () => {
    window.location.href =
      "mailto:haruo.kamioka@gmail.com?subject=Error%20Report&body=" +
      encodeURIComponent(error.message);
  };

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Olá, eu encontrei um erro no Zip Saúde:\n\n ${error.message}`
    );
    const whatsappUrl = `https://wa.me/+5577999920562?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <main className="min-h-screen w-full flex items-center justify-center bg-gradient-to-r from-primary-500/20 to-danger-500/20">
      <Card className="w-[90%] max-w-[450px]">
        <CardHeader className="flex flex-col items-start ">
          <h1 className="text-xl font-semibold">Ops! Algo deu errado</h1>
          <p className="text-default-500">
            Pedimos desculpas pelo inconveniente
          </p>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="w-full bg-default-100 rounded-medium p-2">
              <h2 className="text-default-700 font-semibold text-sm">
                Tente fazer isso:
              </h2>
            </div>
            <Button
              color="primary"
              variant="solid"
              startContent={<RefreshCw size={18} />}
              onPress={handleReload}
            >
              Atualizar a página
            </Button>
            <Button
              color="default"
              variant="bordered"
              startContent={<Home size={18} />}
              onPress={handleReturn}
            >
              Retornar à Página Inicial
            </Button>
          </div>
          <div className="flex flex-col gap-1">
            <div className="w-full bg-default-100 rounded-medium p-2">
              <h2 className="text-default-700 font-semibold text-sm">
                Não funcionou? Clique abaixo
              </h2>
            </div>
            <Button
              color="warning"
              variant="flat"
              startContent={<Mail size={18} />}
              onPress={handleSendEmail}
            >
              Reportar erro
            </Button>
            <Button
              color="secondary"
              variant="flat"
              startContent={<WhatsAppIcon />}
              onPress={handleWhatsAppClick}
              className="bg-secondary-100/50 text-secondary-600"
            >
              Reportar erro
            </Button>
          </div>
        </CardBody>
      </Card>
    </main>
  );
};
