import React, { useCallback, useMemo, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  Progress,
  Spinner,
} from "@nextui-org/react";
import { Star } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import { IntersectionOptions, useInView } from "react-intersection-observer";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";
import { useReviewsQuery } from "../../../hooks/queries/useReviewsQuery";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { Review } from "../../../models/Review";

type Rating = 5 | 4 | 3 | 2 | 1;
type RatingCounts = Record<Rating, number>;
type RatingPercentages = Record<Rating, number>;

const initialRatingCounts: RatingCounts = {
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};

const EmptyReviews = () => (
  <div className="flex flex-col items-center justify-center p-4 text-center gap-4 w-full">
    <div className="bg-primary/10 p-4 rounded-full">
      <Star className="w-8 h-8 text-primary" />
    </div>
    <div className="space-y-2">
      <h3 className="text-xl font-semibold text-default-900">
        Nenhuma avaliação ainda
      </h3>
      <p className="text-default-500 max-w-lg">
        Este profissional ainda não recebeu avaliações. Seja o primeiro a
        compartilhar sua experiência após uma consulta!
      </p>
    </div>
  </div>
);

const calculateRatingStats = (
  reviews: Review[]
): { ratingCounts: RatingCounts; ratingPercentages: RatingPercentages } => {
  const counts = reviews.reduce(
    (acc, review) => {
      const rating = review.rating as Rating;
      acc[rating] = (acc[rating] || 0) + 1;
      return acc;
    },
    { ...initialRatingCounts }
  );

  const total = reviews.length;
  const percentages = Object.entries(counts).reduce<RatingPercentages>(
    (acc, [rating, count]) => {
      const ratingKey = parseInt(rating, 10) as Rating;
      acc[ratingKey] = total > 0 ? (count / total) * 100 : 0;
      return acc;
    },
    {} as RatingPercentages
  );

  return { ratingCounts: counts, ratingPercentages: percentages };
};

interface ProviderReviewsProps {
  provider: ProviderInformation;
}

export const ProviderReviews: React.FC<ProviderReviewsProps> = ({
  provider,
}) => {
  const {
    data: reviews,
    isLoading,
    error,
  } = useReviewsQuery(provider.id as string);

  const { ratingCounts, ratingPercentages } = useMemo(() => {
    if (!reviews)
      return {
        ratingCounts: initialRatingCounts,
        ratingPercentages: {} as RatingPercentages,
      };
    return calculateRatingStats(reviews);
  }, [reviews]);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const options: IntersectionOptions = {
    triggerOnce: true,
    threshold: 0.3,
    root: null,
    rootMargin: "0px",
  };

  const { ref: inViewRef, inView } = useInView(options);
  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      if (headerRef.current) {
        headerRef.current = node;
      }
      inViewRef(node);
    },
    [inViewRef]
  );

  if (isLoading)
    return <Spinner color="primary" label="Carregando Avaliações..." />;
  if (error) return <div>Error loading reviews: {error.message}</div>;

  const hasReviews = reviews && reviews.length > 0;
  const ratings: Rating[] = [5, 4, 3, 2, 1];

  if (!hasReviews) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card shadow="none" radius="lg">
          <EmptyReviews />
        </Card>
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col max-md:flex-col gap-4 items-start max-md:justify-center justify-between w-full">
      <motion.div
        ref={setRefs}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6 }}
        className="flex flex-col gap-2 w-full max-md:static"
      >
        <div className="w-full bg-default-200 rounded-medium p-2">
          <h2 className="text-default-700 font-semibold text-sm">
            O que os Zips estão dizendo
          </h2>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <h3 className="text-[2rem] font-semibold text-default-900">
              {provider.averageRating?.toFixed(1)}
            </h3>
            <div className="flex flex-col">
              <div className="flex flex-row-reverse gap-0.5">
                {ratings.map((star) => (
                  <Star
                    key={star}
                    fill={
                      star <= Math.round(provider.averageRating || 0)
                        ? "currentColor"
                        : "none"
                    }
                  />
                ))}
              </div>
              <span>{provider.totalReviews} avaliações</span>
            </div>
          </div>

          <div className="flex flex-col gap-0.5">
            {ratings.map((rating) => (
              <div key={rating} className="flex items-center gap-2">
                <span className="flex text-right justify-between gap-0.5">
                  {rating}
                  <Star className="w-4" />
                </span>
                <Progress
                  size="md"
                  radius="sm"
                  color="primary"
                  value={ratingPercentages[rating] || 0}
                  className="max-w-md"
                />
                <span className="w-8 text-small text-default-400">
                  {ratingCounts[rating] || 0}
                </span>
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={inView ? { opacity: 1, scale: 1 } : {}}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col gap-3 w-full"
        >
          {reviews.map((review: Review, index: number) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, delay: index * 0.15 }}
            >
              <Card className="w-full" shadow="none" radius="lg">
                <CardHeader className="justify-between items-start p-2">
                  <div className="flex items-start gap-2">
                    <FirebaseAvatar
                      name={review.username || "ZIP"}
                      imageUrl={review.avatarUrl || ""}
                      size="sm"
                      radius="md"
                    />
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">
                        {review.username || "Anônimo"}
                      </span>
                      <span className="text-xs text-default-500">
                        Usuário(a) Zip Saúde
                      </span>
                    </div>
                  </div>
                  <Chip
                    variant="flat"
                    color="primary"
                    startContent={<Star className="w-4 stroke-[3]" />}
                    className="gap-1 px-3 justify-center"
                    classNames={{ content: "px-0 font-semibold text-xs" }}
                  >
                    {review.rating}
                  </Chip>
                </CardHeader>
                {review.comment && (
                  <CardBody className="gap-2 p-2">
                    <p className="text-small text-default-600 text-justify px-0.5">
                      {review.comment}
                    </p>
                  </CardBody>
                )}
              </Card>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
