import React from "react";
import { VerificationIcon } from "../../Molecules/VerificationIcon";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";

interface ProfileHeaderProps {
  name: string;
  photoURL: string;
  displayName: string;
  email: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  photoURL,
  displayName,
  email,
  isEmailVerified,
  isPhoneVerified,
}) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <FirebaseAvatar
        name={name}
        imageUrl={photoURL}
        className="w-16 h-16"
        isBordered
      />
      <h5 className="flex gap-1 justify-center items-center">
        {displayName || email}
        <VerificationIcon
          isEmailVerified={isEmailVerified}
          isPhoneVerified={isPhoneVerified}
        />
      </h5>
    </div>
  );
};
