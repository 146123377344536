import React from "react";
import { Appointment } from "../../../models/Appointment";
import {
  ClipboardPlus,
  HandCoins,
  HeartPulse,
  MapPin,
  Stethoscope,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getColorByStatusCode } from "../../../utils/getColorByStatusCode";
import { UserInformation } from "../../../models/UserInformation";
import { Badge, Card, Chip } from "@nextui-org/react";
import { Clinic } from "../../../models/Clinic";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { FirebaseAvatar } from "../../Atoms/FirebaseAvatar";

interface AppointmentCardProps {
  appointment: Appointment;
  provider: ProviderInformation | undefined;
  examId?: string;
  clinic?: Clinic | undefined;
  user?: UserInformation & { id: string };
  to: string;
  formattedDate: string;
  formattedTime: string;
}

export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  provider,
  examId,
  clinic,
  user,
  to,
  formattedDate,
  formattedTime,
}) => {
  const navigate = useNavigate();

  // If user is provided, we're in provider view (doctor looking at patient)
  // If no user is provided, we're in patient view (patient looking at appointment)
  const isProviderView = !!user;

  const { bg, text } = getColorByStatusCode(appointment.status);
  const entity = clinic ? clinic : provider;

  const getAvatarSrc = () => {
    if (user) {
      return user?.photoURL as string;
    }
    return entity?.imageUrl || undefined;
  };

  const getName = () => {
    if (user) {
      return user.name ? user.name : "";
    }
    return entity?.name || "Unknown";
  };

  const getSubtitle = () => {
    if (user) {
      return "Usuário(a) Zip Saúde";
    }
    if (clinic) {
      return clinic.cnpj;
    }
    return (
      `${provider?.professionalCouncil.conselho}: ${provider?.professionalCouncil.numero} - ${provider?.professionalCouncil.estado}` ||
      ""
    );
  };

  const getClinicName = () => {
    return (
      clinic?.name ||
      provider?.relatedClinic?.name ||
      "Clinic name not available"
    );
  };

  const getClinicAddress = () => {
    const address = clinic?.address || provider?.relatedClinic?.address || "";
    // Truncate with ellipsis if needed
    return address.length > 60 ? address.substring(0, 60) + "..." : address;
  };

  const getPaymentMethod = () => {
    return appointment.paymentMethod || "Payment Method not available";
  };

  const getAppointmentType = () => {
    return examId ? "Exame" : "Consulta";
  };

  // Render different info sections based on whether we're in provider or patient view
  const renderInfoSection = () => {
    if (isProviderView) {
      // Provider view (doctor looking at patient appointment)
      return (
        <div className="flex max-md:flex-col max-md:gap-2 w-full justify-between bg-warning-50 rounded-medium px-1.5 py-2">
          <span className="flex gap-1 items-center">
            <HeartPulse className="w-5 h-5 stroke-[3] text-warning-500" />
            {getClinicName()}
          </span>
          <span className="flex gap-1 items-center">
            <Stethoscope className="w-5 h-5 stroke-[3] text-warning-500" />
            {getAppointmentType()}
          </span>
          <span className="flex gap-1 items-center">
            <HandCoins className="w-5 h-5 stroke-[3] text-warning-500" />
            {getPaymentMethod()}
          </span>
        </div>
      );
    } else {
      // Patient view (patient looking at their own appointment)
      return (
        <div className="flex max-md:flex-col max-md:gap-2 w-full justify-between bg-warning-50 rounded-medium px-1.5 py-2">
          <span className="flex gap-1 items-center">
            <HeartPulse className="w-5 h-5 stroke-[3] text-warning-500" />
            {getClinicName()}
          </span>
          <span className="flex gap-1 items-center truncate">
            <MapPin className="w-5 h-5 stroke-[3] text-warning-500 flex-shrink-0" />
            <span className="truncate">{getClinicAddress()}</span>
          </span>
          <span className="flex gap-1 items-center">
            <HandCoins className="w-5 h-5 stroke-[3] text-warning-500" />
            {getPaymentMethod()}
          </span>
        </div>
      );
    }
  };

  const CardContent = (
    <Card
      isPressable
      className={`bg-white rounded-large w-full min-w-[calc(50.00%-1rem)] p-2 gap-3 flex flex-col justify-between items-center flex-wrap`}
      shadow="none"
      onPress={() => navigate(`${to}`)}
    >
      <div className="flex w-full justify-between ">
        <div className="flex gap-2">
          <FirebaseAvatar
            imageUrl={getAvatarSrc() || ""}
            name={getName()}
            size="lg"
            radius="md"
          />
          <div className="flex flex-col items-start py-0.5">
            <span className="text-sm font-medium text-default-900">
              {getName()}
            </span>
            <span className="text-sm font-medium text-default-400">
              {getSubtitle()}
            </span>
          </div>
        </div>

        <Chip
          variant="flat"
          color={examId ? "secondary" : "primary"}
          startContent={
            examId ? (
              <ClipboardPlus className="w-4 h-4 ml-1 text-secondary-500" />
            ) : (
              <Stethoscope className="w-4 h-4 ml-1 text-primary-500" />
            )
          }
        >
          {examId ? "Exame" : "Consulta"}
        </Chip>
      </div>

      <div className="flex flex-col w-full gap-1">
        {renderInfoSection()}

        <div
          className={`${bg} rounded-medium p-2 flex justify-center items-center gap-1 w-full`}
        >
          <span className={`${text} text-2xl font-semibold`}>
            {formattedDate} - {formattedTime}
          </span>
        </div>
      </div>
    </Card>
  );

  if (appointment.status === "COMPLETED" && !appointment.hasReview && !user) {
    return (
      <Badge
        size="lg"
        content=""
        color="primary"
        placement="top-right"
        className="animate-pulse"
        classNames={{ base: "flex", badge: "w-5 h-5 top-1 right-1" }}
      >
        {CardContent}
      </Badge>
    );
  }

  return CardContent;
};
