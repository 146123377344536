import { useQuery } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
  where,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";

const fetchProvidersByIds = async (
  providerIds: string[]
): Promise<ProviderInformation[]> => {
  if (providerIds.length === 0) return [];

  const providersQuery = query(
    collection(fireStoreDb, "providers"),
    where("id", "in", providerIds)
  );

  const querySnapshot = await getDocs(providersQuery);

  const providers = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {
        schedules: {},
        timezone: "America/Sao_Paulo",
        status: "active",
      };
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
      } as ProviderInformation;
    })
  );

  return providers;
};

export const useProvidersQuery = (providerIds: string[]) => {
  return useQuery<ProviderInformation[], Error>({
    queryKey: ["providers", providerIds],
    queryFn: () => fetchProvidersByIds(providerIds),
    enabled: providerIds.length > 0,
  });
};
