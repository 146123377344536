import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  getDoc,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ZipBio } from "../../models/ZipBio";
import { ProviderInformation } from "../../models/ProviderInformation";

interface CreateZipBioParams {
  providerId: string;
  customLink: string;
}

export const useCreateZipBio = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ providerId, customLink }: CreateZipBioParams) => {
      try {
        // First check if customLink is available
        const linkQuery = query(
          collection(fireStoreDb, "zipBios"),
          where("customLink", "==", customLink)
        );
        const linkSnapshot = await getDocs(linkQuery);

        if (!linkSnapshot.empty) {
          throw new Error("Este link já está sendo usado");
        }

        // Fetch provider data to get their name
        const providerDoc = await getDoc(
          doc(fireStoreDb, "providers", providerId)
        );

        if (!providerDoc.exists()) {
          throw new Error("Prestador não encontrado");
        }

        const providerData = providerDoc.data() as ProviderInformation;

        // Create new ZipBio document with provider's name
        const newZipBio: Omit<ZipBio, "id"> = {
          providerId,
          name: providerData.name || "",
          bio: providerData.bio || "",
          customLink,
          contactMethods: [],
          imageUrl: providerData.imageUrl || "",
          redirectMethods: {
            whatsApp: false,
            zipSaude: true,
          },
          createdAt: new Date(),
          updatedAt: new Date(),
        };

        await setDoc(doc(fireStoreDb, "zipBios", providerId), newZipBio);
        return newZipBio;
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "Prestador não encontrado") {
            throw new Error(
              "Não foi possível criar seu ZipBio. Por favor, tente novamente mais tarde."
            );
          }
          throw error;
        }
        throw new Error(
          "Ocorreu um erro ao criar seu ZipBio. Por favor, tente novamente."
        );
      }
    },
    onSuccess: async (_, variables) => {
      // Batch invalidate queries to ensure they update together
      queryClient.invalidateQueries({
        queryKey: [
          ["zipBio", variables.providerId],
          ["provider", variables.providerId],
        ],
      });
    },
    onError: (error: Error) => {
      console.error("Error creating ZipBio:", error);
    },
  });
};

export type CreateZipBioMutation = ReturnType<typeof useCreateZipBio>;
