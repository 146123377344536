// types.ts
export interface UploadState {
  progress: number;
  isUploading: boolean;
  isSuccess: boolean;
  previewUrl: string;
}

export interface UseFileUploadOptions {
  storage: any; // Replace with proper Firebase storage type
  fireStoreDb: any; // Replace with proper Firestore type
  providerId: string;
  collectionName: string;
  onSuccess?: () => void;
}

export interface UseFileUploadReturn {
  uploadState: UploadState;
  handleFileUpload: (file: File) => Promise<void>;
  resetUploadState: () => void;
}

// useFileUpload.ts
import { useState, useRef } from "react";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";

const INITIAL_UPLOAD_STATE: UploadState = {
  progress: 0,
  isUploading: false,
  isSuccess: false,
  previewUrl: "",
};

export const useFileUpload = ({
  storage,
  fireStoreDb,
  providerId,
  collectionName,
  onSuccess,
}: UseFileUploadOptions): UseFileUploadReturn => {
  const [uploadState, setUploadState] =
    useState<UploadState>(INITIAL_UPLOAD_STATE);

  const progressTimerRef = useRef<NodeJS.Timeout | null>(null);
  const successTimerRef = useRef<NodeJS.Timeout | null>(null);

  const resetUploadState = () => {
    setUploadState(INITIAL_UPLOAD_STATE);
  };

  const cleanupTimers = () => {
    if (progressTimerRef.current) {
      clearInterval(progressTimerRef.current);
      progressTimerRef.current = null;
    }
    if (successTimerRef.current) {
      clearTimeout(successTimerRef.current);
      successTimerRef.current = null;
    }
  };

  const simulateProgress = (actualProgress: number) => {
    cleanupTimers();

    const remaining = 100 - actualProgress;
    const steps = Math.floor(remaining / 5);
    const interval = 100;

    progressTimerRef.current = setInterval(() => {
      setUploadState((prev) => {
        const increment = Math.min(5, 100 - prev.progress);
        const newProgress = prev.progress + increment;

        if (newProgress >= 100) {
          cleanupTimers();
        }

        return {
          ...prev,
          progress: newProgress,
        };
      });
    }, interval * (steps / 10));
  };

  const updateUserPhotoUrl = async (downloadUrl: string) => {
    try {
      // Query the users collection to find users with matching providerId
      const usersRef = collection(fireStoreDb, "users");
      const q = query(usersRef, where("providerId", "==", providerId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching user found with providerId:", providerId);
        return;
      }

      // Update each matching user's photoUrl
      const updatePromises = querySnapshot.docs.map((userDoc) =>
        updateDoc(doc(fireStoreDb, "users", userDoc.id), {
          photoURL: downloadUrl,
        })
      );

      await Promise.all(updatePromises);
      console.log(`Updated photoUrl for ${updatePromises.length} user(s)`);
    } catch (error) {
      console.error("Error updating user photoUrl:", error);
      // Don't throw here to prevent affecting the main flow
    }
  };

  const handleFileUpload = async (file: File): Promise<void> => {
    if (!providerId) {
      toast.error("Provider ID is required");
      return;
    }

    try {
      cleanupTimers();

      setUploadState({
        progress: 0,
        isUploading: true,
        isSuccess: false,
        previewUrl: URL.createObjectURL(file),
      });

      const filename = `${providerId}-profile-img`;
      const storageRef = ref(storage, `files/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const actualProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setUploadState((prev) => ({
            ...prev,
            progress: Math.min(actualProgress, 90),
          }));

          if (actualProgress > 90) {
            simulateProgress(actualProgress);
          }
        },
        (error) => {
          console.error(error);
          setUploadState((prev) => ({
            ...prev,
            isUploading: false,
            progress: 0,
          }));
          toast.error("Erro ao enviar imagem");
        },
        async () => {
          try {
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

            setUploadState((prev) => ({
              ...prev,
              progress: 100,
              isUploading: false,
              isSuccess: true,
            }));

            // Update provider document
            await updateDoc(doc(fireStoreDb, collectionName, providerId), {
              imageUrl: downloadUrl,
            });

            // Update users where providerId matches
            updateUserPhotoUrl(downloadUrl);

            successTimerRef.current = setTimeout(() => {
              setUploadState((prev) => ({
                ...prev,
                isSuccess: false,
              }));
            }, 1500);

            onSuccess?.();
            toast.success("Imagem enviada com sucesso!");
          } catch (error) {
            console.error("Error updating provider:", error);
            toast.error("Erro ao enviar imagem, por favor, tente novamente.");
          }
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
      setUploadState((prev) => ({
        ...prev,
        isUploading: false,
        progress: 0,
      }));
      toast.error("Erro ao enviar imagem");
    }
  };

  return {
    uploadState,
    handleFileUpload,
    resetUploadState,
  };
};
