import React from "react";
import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  MapPinIcon,
  TikTokIcon,
  WhatsAppIcon,
  YouTubeIcon,
} from "../components/Atoms/Icons";
import { cleanPhoneNumber, normalizePhoneNumber } from "./phoneNumberUtils";
import { getEnv } from "../services/firebase/firebase";

export interface PlatformOption {
  id: string;
  label: string;
  Icon: React.FC<{ size?: "sm" | "lg"; className?: string }>;
  color: string;
  placeholder?: string;
  prefix?: string;
  format?: (value: string) => string;
  validate?: (value: string) => boolean;
  clean?: (value: string) => string;
  helperText?: string;
  getLinkFromValue?: (value: string) => string;
  getValueFromLink?: (link: string) => string;
  defaultParams?: Record<string, string>;
}

// Helper function to handle URL parameters
const addParamsToUrl = (url: string, params: Record<string, string> = {}) => {
  // Ensure we have a proper URL to work with
  const urlWithProtocol = url.startsWith("http") ? url : `https://${url}`;
  const urlObj = new URL(urlWithProtocol);

  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });

  return urlObj.toString();
};

export const PLATFORM_OPTIONS: PlatformOption[] = [
  {
    id: "instagram",
    label: "Instagram",
    Icon: InstagramIcon,
    color: "text-pink-600",
    placeholder: "Digite o seu @",
    prefix: "@",
    defaultParams: { r: "nametag" },
    getLinkFromValue: (value) => {
      const username = value.replace("@", "");
      return addParamsToUrl(`instagram.com/${username}`, { r: "nametag" });
    },
    getValueFromLink: (link) => {
      const match = link.match(/instagram\.com\/([^/?]+)/);
      return match ? match[1] : link;
    },
    validate: (value) => /^@?[\w.](?!.*?\.{2})[\w.]+$/i.test(value),
    clean: (value) => value.replace("@", ""),
    helperText:
      "Seu @ é o nome que aparece logo no topo da tela do seu perfil. Ex: @drwallacebatista",
  },
  {
    id: "whatsapp",
    label: "WhatsApp",
    Icon: WhatsAppIcon,
    color: "text-green-600",
    placeholder: "(XX) XXXXX-XXXX",
    prefix: "+55",
    defaultParams: { text: "Olá! Vim através da sua ZipBio." },
    getLinkFromValue: (value) => {
      const cleanedNumber = cleanPhoneNumber(value);
      return `https://wa.me/55${cleanedNumber}?text=${encodeURIComponent(
        "Olá! Vim através da sua ZipBio."
      )}`;
    },
    getValueFromLink: (link) => {
      const match = link.match(/wa\.me\/55(\d+)/);
      return match ? normalizePhoneNumber(match[1]) : link;
    },
    validate: (value) => {
      const cleaned = cleanPhoneNumber(value);
      return cleaned.length === 10 || cleaned.length === 11;
    },
    clean: (value) => {
      const cleaned = cleanPhoneNumber(value);
      return normalizePhoneNumber(cleaned);
    },
    helperText:
      "Digite seu número do WhatsApp completo, incluindo o DDD. Ex: (11) 99999-9999",
  },
  {
    id: "tiktok",
    label: "TikTok",
    Icon: TikTokIcon,
    color: "text-black",
    placeholder: "Digite o seu @",
    prefix: "@",
    getLinkFromValue: (value) =>
      `https://tiktok.com/@${value.replace("@", "")}`,
    getValueFromLink: (link) => {
      const match = link.match(/tiktok\.com\/@([^/?]+)/);
      return match ? match[1] : link;
    },
    validate: (value) => /^@?[\w.](?!.*?\.{2})[\w.]+$/i.test(value),
    clean: (value) => value.replace("@", ""),
    helperText:
      "Seu nome de usuário pode ser encontrado no seu perfil, logo abaixo do seu nome de exibição. Ex: drwallace",
  },
  {
    id: "facebook",
    label: "Facebook",
    Icon: FacebookIcon,
    color: "text-blue-600",
    placeholder: "Sua página ou perfil",
    getLinkFromValue: (value) => `https://facebook.com/${value}`,
    getValueFromLink: (link) => {
      const match = link.match(/facebook\.com\/([^/?]+)/);
      return match ? match[1] : link;
    },
    helperText:
      "Você pode encontrar seu nome de usuário do Facebook nas configurações do perfil.",
  },
  {
    id: "youtube",
    label: "YouTube",
    Icon: YouTubeIcon,
    color: "text-red-600",
    placeholder: "Digite o seu @",
    getLinkFromValue: (value) => `https://youtube.com/${value}`,
    getValueFromLink: (link) => {
      const match = link.match(/youtube\.com\/([^/?]+)/);
      return match ? match[1] : link;
    },
    helperText:
      "Seu @ do YouTube pode ser encontrado nas configurações do canal ou abaixo do nome do seu canal na página inicial.",
  },
  {
    id: "google-maps",
    label: "Localização",
    Icon: MapPinIcon,
    color: "text-red-600",
    placeholder: "Digite seu endereço",
    helperText: "Pesquise seu endereço completo para exibir no mapa",
    getLinkFromValue: (value) => {
      const placeId = value;
      return `https://www.google.com/maps/embed/v1/place?key=${getEnv(
        "VITE_GOOGLE_MAPS_API_KEY"
      )}&q=place_id:${placeId}`;
    },
    validate: (value) => !!value.match(/^[a-zA-Z0-9_-]+$/),
    getValueFromLink: (link) => {
      const match = link.match(/place_id=([a-zA-Z0-9_-]+)/);
      return match ? match[1] : link;
    },
  },
  {
    id: "website",
    label: "Website",
    Icon: GlobeIcon,
    color: "text-gray-700",
    placeholder: "Seu website",
    getLinkFromValue: (value) => `https://${value.replace(/^https?:\/\//, "")}`,
    getValueFromLink: (link) => link.replace(/^https?:\/\//, ""),
    helperText:
      "Digite o endereço completo do seu site, sem incluir 'https://'.",
  },
];

export const getPlatformDetails = (platformId: string): PlatformOption => {
  return (
    PLATFORM_OPTIONS.find((p) => p.id === platformId) || {
      id: "website",
      label: "Website",
      Icon: GlobeIcon,
      color: "text-gray-600",
      getLinkFromValue: (value) =>
        `https://${value.replace(/^https?:\/\//, "")}`,
      getValueFromLink: (link) => link.replace(/^https?:\/\//, ""),
    }
  );
};
