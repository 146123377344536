import React from "react";
import { Return } from "../../Molecules/Return";

export const BookingTemplate = ({
  title,
  header,
  children,
  hasReviews = false,
}: {
  title: string;
  header: React.ReactNode;
  children: React.ReactNode;
  hasReviews?: boolean;
}) => (
  <main
    className={`flex flex-col justify-start gap-8 items-center min-h-svh p-4 pb-20 w-full ${
      hasReviews ? "max-w-[1440px]" : "max-w-2xl"
    }  mx-auto`}
  >
    <Return route="/" title={title} />
    <div
      className={`flex w-full ${
        hasReviews ? "max-md:flex-col gap-6 justify-between" : "justify-center"
      }`}
    >
      <div
        className={`flex flex-col gap-4 ${
          hasReviews ? "flex-1 w-full max-w-3xl" : "w-full max-w-2xl"
        }`}
      >
        {header}
        {!hasReviews && children}
      </div>
      {hasReviews && (
        <div className="xl:max-w-xl lg:max-w-lg md:max-w-sm w-full">
          {children}
        </div>
      )}
    </div>
  </main>
);
