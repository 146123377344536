import { AutocompleteItem, Button } from "@nextui-org/react";
import { Autocomplete } from "../../Atoms/Autocomplete";
import { ClipboardPlus, Search, Stethoscope } from "lucide-react";
import { State } from "../../../models/State";
import { City } from "../../../models/City";
import { MedicalService } from "../../../models/MedicalService";
import { GeolocationHandler } from "../../Organisms/GeolocationHandler";
import { Key } from "react";

interface SearchProviderProps {
  selectedState: State | undefined;
  selectedCity: City | undefined;
  selectedMedicalService: MedicalService | undefined;
  stateItems: { key: string; value: string; label: string }[];
  cityItems: { key: string; value: string; label: string }[];
  medicalServiceItems: {
    key: string;
    value: string;
    label: string;
    type: "specialty" | "exam" | "service";
  }[];
  onStateChange: (id: string | null) => void;
  onCityChange: (id: string | null) => void;
  onMedicalServiceChange: (id: string | null) => void;
  onLocationSearch: (latitude: number, longitude: number) => Promise<void>;
  onSearch: (e: React.FormEvent) => void;
  isDisabled: boolean;
  isLoadingStates: boolean;
  isLoadingCities: boolean;
  isLoadingMedicalServices: boolean;
  locationSearchLoading: boolean;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({
  selectedState,
  selectedCity,
  selectedMedicalService,
  stateItems,
  cityItems,
  medicalServiceItems,
  onStateChange,
  onCityChange,
  onMedicalServiceChange,
  onLocationSearch,
  onSearch,
  isDisabled,
  isLoadingStates,
  isLoadingCities,
  isLoadingMedicalServices,
}) => {
  const handleLocationSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    onLocationSearch(latitude, longitude);
  };

  const handleLocationError = (error: GeolocationPositionError) => {
    console.error("Geolocation error:", error);
    // Handle the error appropriately, e.g., show a notification to the user
  };

  return (
    <form onSubmit={onSearch} className="flex flex-col gap-1">
      <div className="flex max-lg:flex-col gap-1">
        <Autocomplete
          size="lg"
          label="Selecione o seu estado"
          variant="faded"
          isLoading={isLoadingStates}
          onSelectionChange={(key: Key) =>
            onStateChange(key !== null ? String(key) : null)
          }
          selectedKey={selectedState?.id.toString() || null}
          value={selectedState?.id.toString()}
          defaultItems={stateItems}
          listboxProps={{
            emptyContent: "Estado não encontrado.",
          }}
        >
          {(item: { key: string; value: string; label: string }) => (
            <AutocompleteItem
              key={item.key.toString()}
              value={item.key.toString()}
              color="secondary"
              variant="flat"
              className="py-2"
            >
              {item.label}
            </AutocompleteItem>
          )}
        </Autocomplete>

        {selectedState && (
          <Autocomplete
            size="lg"
            label="Selecione a sua cidade"
            variant="faded"
            isLoading={isLoadingCities}
            onSelectionChange={(key: Key) =>
              onCityChange(key !== null ? String(key) : null)
            }
            selectedKey={selectedCity?.id.toString() || null}
            value={selectedCity?.id.toString()}
            isDisabled={!selectedState}
            defaultItems={cityItems}
            listboxProps={{
              emptyContent: "Cidade não encontrada.",
            }}
          >
            {(item: any) => (
              <AutocompleteItem
                key={item.key}
                value={item.key}
                color="secondary"
                variant="flat"
                className="py-2"
              >
                {item.label}
              </AutocompleteItem>
            )}
          </Autocomplete>
        )}

        <Autocomplete
          size="lg"
          label="O que você precisa?"
          variant="faded"
          isLoading={isLoadingMedicalServices}
          onSelectionChange={(key: Key) =>
            onMedicalServiceChange(key ? key.toString() : null)
          }
          selectedKey={selectedMedicalService?.id || null}
          defaultItems={medicalServiceItems}
          listboxProps={{
            emptyContent: "Serviço médico não encontrado.",
          }}
        >
          {(item: any) => (
            <AutocompleteItem
              key={item.key}
              textValue={item.label}
              color="secondary"
              variant="flat"
              className="h-auto"
              startContent={
                item.type === "exam" ? (
                  <div className="flex items-center justify-center p-2 rounded-full bg-secondary-50">
                    <ClipboardPlus className="w-5 h-5 text-secondary-600" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center p-2 rounded-full bg-primary-50">
                    <Stethoscope className="w-5 h-5 text-primary-600" />
                  </div>
                )
              }
            >
              {item.label}
            </AutocompleteItem>
          )}
        </Autocomplete>
      </div>

      <div className="flex justify-between items-center mx-0.5">
        <GeolocationHandler
          onLocationSuccess={handleLocationSuccess}
          onLocationError={handleLocationError}
        />
        <Button
          size="md"
          variant="shadow"
          color="primary"
          endContent={<Search className="w-4" />}
          className="font-medium"
          isDisabled={isDisabled}
          type="submit"
        >
          Buscar
        </Button>
      </div>
    </form>
  );
};
