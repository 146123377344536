import React from "react";
import { Globe, MapPin } from "lucide-react";
import { Instagram } from "../../../assets/Instagram";
import { WhatsApp } from "../../../assets/WhatsApp";
import { TikTok } from "../../../assets/TikTok";
import { Facebook } from "../../../assets/Facebook";
import { YouTube } from "../../../assets/YouTube";

interface IconProps {
  size?: "sm" | "lg";
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
}

// Create size-aware icon components
export const InstagramIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <Instagram
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const WhatsAppIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <WhatsApp
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const TikTokIcon: React.FC<IconProps> = ({ size = "sm", className }) => (
  <TikTok
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const FacebookIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <Facebook
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const YouTubeIcon: React.FC<IconProps> = ({
  size = "sm",
  className,
}) => (
  <YouTube
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    className={className}
  />
);

export const GlobeIcon: React.FC<IconProps> = ({ size = "sm", className }) => (
  <Globe size={size === "sm" ? 24 : 32} className={className} />
);

export const MapPinIcon: React.FC<IconProps> = ({ size = "sm", className }) => (
  <MapPin size={size === "sm" ? 24 : 32} className={className} />
);

export const AddItemIcon: React.FC<IconProps> = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
    <svg
      fill="none"
      height={size || height || 24}
      viewBox="0 0 24 24"
      width={size || width || 24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5V19M5 12H19"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockCircleLinearIcon = () => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      className="text-xl text-default-400 pointer-events-none flex-shrink-0"
    >
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <circle cx="12" cy="12" r="10" />
        <path
          d="M12 8v4l2.5 2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
