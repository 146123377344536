import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { PlusCircle, X, Undo2, CheckCircle2 } from "lucide-react";
import {
  getPlatformDetails,
  PLATFORM_OPTIONS,
} from "../../../utils/platformUtils";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { fireStoreDb } from "../../../services/firebase/firebase";
import { GoogleMapsInput, PhoneNumberInput } from "../Inputs";

interface AddContactMethodCardProps {
  providerId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const cardVariants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: 10,
    transition: { duration: 0.2, ease: "easeOut" },
  },
};

const successAnimation = {
  scale: [1, 1.2, 1],
  rotate: [0, 360, 360],
  transition: {
    duration: 0.8,
    ease: "easeInOut",
    delay: 0.07,
  },
};

const inputGroupVariants = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25,
      staggerChildren: 0.1,
    },
  },
  exit: {
    y: -20,
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const platformIconVariants = {
  initial: { rotate: -180, opacity: 0, scale: 0.5 },
  animate: {
    rotate: 0,
    opacity: 1,
    scale: 1,
    transition: { type: "spring", stiffness: 200, damping: 20 },
  },
  exit: {
    rotate: 180,
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.2,
    },
  },
};

export const AddContactMethodCard: React.FC<AddContactMethodCardProps> = ({
  providerId,
  onSuccess,
  onCancel,
}) => {
  const [addingContactStep, setAddingContactStep] = useState("platform");
  const [newContact, setNewContact] = useState({ platform: "", link: "" });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [inputError, setInputError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const platformConfig = getPlatformDetails(newContact.platform);
  const { Icon } = platformConfig;

  const validateInput = (value: string): boolean => {
    if (!value.trim()) {
      setInputError("Campo obrigatório");
      return false;
    }
    if (platformConfig.validate && !platformConfig.validate(value)) {
      setInputError(`Formato inválido para ${platformConfig.label}`);
      return false;
    }
    setInputError("");
    return true;
  };

  const handleReturn = () => {
    setNewContact({ link: "", platform: "" });
    setAddingContactStep("platform");
    setInputError("");
  };

  const shouldShowCancelConfirmation =
    addingContactStep === "link" && newContact.link.trim() !== "";

  const handleAdd = async () => {
    setIsSaving(true);
    try {
      const formattedLink =
        platformConfig.getLinkFromValue?.(newContact.link) ||
        `https://${newContact.link}`;

      const newMethod = {
        id: Date.now().toString(),
        platform: newContact.platform,
        link: formattedLink,
      };

      const providerRef = doc(fireStoreDb, "zipBios", providerId);
      await updateDoc(providerRef, {
        contactMethods: arrayUnion(newMethod),
      });

      setShowSuccess(true);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      onSuccess();
      onCancel();
      toast.success("Método de contato adicionado com sucesso!");
    } catch (error) {
      console.error("Error adding contact method:", error);
      toast.error("Erro ao adicionar método de contato.");
      setShowSuccess(false);
    } finally {
      setIsSaving(false);
    }
  };

  const renderInput = () => {
    if (newContact.platform === "whatsapp") {
      return (
        <PhoneNumberInput
          size="lg"
          radius="md"
          variant="flat"
          label={platformConfig.label}
          value={newContact.link}
          onChange={(value) => {
            const cleanValue = platformConfig.clean?.(value) ?? value;
            setNewContact((prev) => ({ ...prev, link: cleanValue }));
            validateInput(cleanValue);
          }}
          isInvalid={!!inputError}
          errorMessage={inputError}
        />
      );
    }

    if (newContact.platform === "google-maps") {
      return (
        <GoogleMapsInput
          size="lg"
          radius="md"
          variant="flat"
          label="Localização"
          placeholder="Pesquisar endereço..."
          onPlaceSelect={(placeId) => {
            setNewContact((prev) => ({ ...prev, link: placeId }));
            setInputError("");
          }}
          isInvalid={!!inputError}
          errorMessage={inputError}
        />
      );
    }

    return (
      <Input
        size="lg"
        radius="md"
        variant="flat"
        label={platformConfig.label}
        placeholder={platformConfig.placeholder || "Seu link aqui"}
        value={newContact.link}
        onValueChange={(value) => {
          const cleanValue = platformConfig.clean?.(value) ?? value;
          setNewContact((prev) => ({ ...prev, link: cleanValue }));
          validateInput(cleanValue);
        }}
        startContent={
          platformConfig.id === "website" ? (
            <span className="text-default-400 text-small">https://</span>
          ) : platformConfig.prefix ? (
            <span className="text-default-400 text-small">
              {platformConfig.prefix}
            </span>
          ) : null
        }
        isInvalid={!!inputError}
        errorMessage={inputError}
      />
    );
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="w-full"
    >
      <Card shadow="none" radius="lg" className="p-0 w-full">
        <CardBody className="flex flex-col items-center gap-4 p-2 overflow-hidden">
          <motion.div
            className="w-full flex justify-between items-start"
            variants={inputGroupVariants}
          >
            <div className="flex gap-2 items-center">
              <AnimatePresence mode="wait">
                {newContact.platform ? (
                  <motion.div
                    key="platform-icon"
                    variants={platformIconVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className={getPlatformDetails(newContact.platform).color}
                  >
                    <Icon size="lg" />
                  </motion.div>
                ) : (
                  <motion.div
                    key="plus-icon"
                    variants={platformIconVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <PlusCircle size={32} className="text-default-600" />
                  </motion.div>
                )}
                <span className="text-default-700 font-semibold">
                  {newContact.platform
                    ? getPlatformDetails(newContact.platform).label
                    : "Adicionar Método"}
                </span>
              </AnimatePresence>
            </div>

            <div className="flex gap-2">
              <AnimatePresence>
                {addingContactStep === "link" && (
                  <motion.div
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                  >
                    <Button
                      isIconOnly
                      aria-label="Voltar"
                      variant="flat"
                      radius="full"
                      size="sm"
                      onPress={handleReturn}
                    >
                      <Undo2 size={16} className="stroke-[3]" />
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>

              {shouldShowCancelConfirmation ? (
                <Popover
                  isOpen={isPopoverOpen}
                  onOpenChange={setIsPopoverOpen}
                  placement="bottom-end"
                  radius="lg"
                  backdrop="blur"
                  showArrow
                >
                  <PopoverTrigger>
                    <Button
                      isIconOnly
                      aria-label="Cancelar"
                      variant="flat"
                      color="danger"
                      radius="full"
                      size="sm"
                    >
                      <X size={16} className="stroke-[3]" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="flex flex-col gap-3 p-2 items-start max-w-60">
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="flex flex-col gap-2"
                    >
                      <span className="text-small font-bold">
                        Cancelar adição
                      </span>
                      <span className="text-tiny text-default-600">
                        Tem certeza que deseja cancelar? Os dados inseridos
                        serão perdidos.
                      </span>
                      <div className="flex justify-end gap-2">
                        <Button
                          size="sm"
                          radius="md"
                          variant="flat"
                          onPress={() => setIsPopoverOpen(false)}
                        >
                          Voltar
                        </Button>
                        <Button
                          size="sm"
                          radius="md"
                          color="danger"
                          variant="flat"
                          onPress={onCancel}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </motion.div>
                  </PopoverContent>
                </Popover>
              ) : (
                <Button
                  isIconOnly
                  aria-label="Cancelar"
                  variant="flat"
                  color="danger"
                  radius="full"
                  size="sm"
                  onPress={onCancel}
                >
                  <X size={16} className="stroke-[3]" />
                </Button>
              )}
            </div>
          </motion.div>

          <AnimatePresence mode="wait">
            {addingContactStep === "platform" ? (
              <motion.div
                key="platform-select"
                variants={inputGroupVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full"
              >
                <Select
                  size="lg"
                  radius="md"
                  label="Plataforma"
                  placeholder="Selecione uma plataforma"
                  selectedKeys={
                    newContact.platform ? [newContact.platform] : []
                  }
                  onSelectionChange={(keys) => {
                    const selected = Array.from(keys)[0]?.toString() || "";
                    setNewContact((prev) => ({ ...prev, platform: selected }));
                    setAddingContactStep("link");
                  }}
                  classNames={{
                    value: "flex items-center gap-2",
                  }}
                >
                  {PLATFORM_OPTIONS.map((platform) => (
                    <SelectItem
                      variant="flat"
                      color="primary"
                      key={platform.id}
                      value={platform.id}
                      startContent={
                        <div className={platform.color}>
                          <platform.Icon size="sm" />
                        </div>
                      }
                    >
                      {platform.label}
                    </SelectItem>
                  ))}
                </Select>
              </motion.div>
            ) : (
              <motion.div
                key="link-input"
                variants={inputGroupVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full flex flex-col gap-2"
              >
                {platformConfig.helperText && (
                  <p className="text-xs text-default-500 px-1">
                    Dica: {platformConfig.helperText}
                  </p>
                )}
                {renderInput()}
                <Button
                  color="primary"
                  size="lg"
                  radius="md"
                  onPress={handleAdd}
                  isLoading={isSaving}
                  isDisabled={!newContact.link || !!inputError}
                  className="w-full"
                >
                  Confirmar
                </Button>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showSuccess && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 overflow-hidden flex items-center justify-center bg-success/10 backdrop-blur-[8px] z-20"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={successAnimation}
                  className="flex items-center justify-center"
                >
                  <CheckCircle2 className="text-success" size={48} />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </motion.div>
  );
};
