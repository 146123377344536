import {
  Autocomplete,
  AutocompleteItem,
  Input,
  InputProps,
  Select,
  SelectItem,
} from "@nextui-org/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PasswordToggle } from "../../Atoms/PasswordToggle";
import { motion, AnimatePresence } from "framer-motion";
import { Check } from "lucide-react";
import { OTPInput as InputOTP, SlotProps } from "input-otp";
import { validateEmail } from "../../../utils/validateEmail";
import {
  cleanPhoneNumber,
  normalizePhoneNumber,
} from "../../../utils/phoneNumberUtils";
import { getEnv } from "../../../services/firebase/firebase";

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
  placeholder?: string;
  labelPlacement?: "inside" | "outside" | "outside-left";
  variant?: "flat" | "faded" | "bordered" | "underlined" | undefined;
}

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  variant?: "faded" | "flat" | "bordered" | "underlined" | undefined;
}

interface PhoneNumberInputProps extends Omit<InputProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
}
interface OTPInputProps {
  verificationCode: string;
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
}

interface CPFInputProps {
  cpf: string;
  setCpf: (value: string) => void;
}

interface CNPJInputProps {
  cnpj: string;
  setCnpj: (value: string) => void;
}

declare global {
  interface Window {
    google: any;
  }
}

interface GoogleMapsInputProps {
  onPlaceSelect: (placeId: string, address: string) => void;
  [key: string]: any;
}

const states = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const areaToCouncils: { [key: string]: string } = {
  Medicina: "CRM",
  Enfermagem: "COREN",
  Psicologia: "CRP",
  Odontologia: "CRO",
  Nutrição: "CRN",
  Fisioterapia: "CREFITO",
};

const areaOptions = [
  "Medicina",
  "Enfermagem",
  "Psicologia",
  "Odontologia",
  "Nutrição",
  "Fisioterapia",
];

interface ProfessionalCouncilData {
  area: string;
  conselho: string;
  numero: string;
  estado: string;
}

interface ProfessionalCouncilInputProps {
  value: ProfessionalCouncilData;
  onChange: (field: keyof ProfessionalCouncilData, value: string) => void;
  onBlur?: () => void;
  isInvalid?: boolean;
  errorMessage?: string;
  labelPlacement?: "inside" | "outside" | "outside-left";
}

const counterVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
};

const inputContainerVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const inputItemVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
};

const formatCPF = (cpf: string) => {
  let newCpf = cpf;
  newCpf = newCpf.replace(/\D/g, "");
  newCpf = newCpf.replace(/(\d{3})(\d)/, "$1.$2");
  newCpf = newCpf.replace(/(\d{3})(\d)/, "$1.$2");
  newCpf = newCpf.replace(/(\d{3})(\d)/, "$1-$2");
  newCpf = newCpf.replace(/([0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2})(.*)/, "$1");
  return newCpf;
};

const formatCNPJ = (cnpj: string) => {
  let newCnpj = cnpj;
  newCnpj = newCnpj.replace(/\D/g, "");
  newCnpj = newCnpj.substring(0, 14);
  newCnpj = newCnpj.replace(/^(\d{2})(\d)/, "$1.$2");
  newCnpj = newCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  newCnpj = newCnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  newCnpj = newCnpj.replace(/(\d{4})(\d)/, "$1-$2");
  return newCnpj;
};

export const EmailInput: React.FC<EmailInputProps> = ({
  email,
  setEmail,
  labelPlacement,
  placeholder = "Digite o seu e-mail",
  variant = "faded",
}) => {
  const isInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  return (
    <Input
      size="lg"
      type="email"
      label="E-mail"
      placeholder={placeholder}
      variant={variant}
      labelPlacement={labelPlacement}
      isInvalid={isInvalid}
      color={isInvalid ? "danger" : "default"}
      errorMessage={({ validationDetails }) => {
        if (validationDetails.valueMissing) {
          return "Por favor, digite o endereço de e-mail";
        }
        if (validationDetails.typeMismatch) {
          return "Por favor, digite um endereço de e-mail válido";
        }
      }}
      value={email}
      onValueChange={setEmail}
      isRequired
    />
  );
};

export const CpfInput: React.FC<CPFInputProps> = ({ cpf, setCpf }) => {
  return (
    <Input
      size="lg"
      isRequired
      errorMessage={({ validationDetails }) => {
        if (validationDetails.valueMissing) {
          return "Por favor, digite o seu CPF";
        }
        if (validationDetails.typeMismatch) {
          return "Por favor, digite um CPF válido";
        }
      }}
      label="CPF"
      labelPlacement="outside"
      name="cpf"
      placeholder="Digite o seu CPF"
      value={formatCPF(cpf)}
      onValueChange={setCpf}
      type="text"
      minLength={11}
    />
  );
};

export const CNPJInput: React.FC<CNPJInputProps> = ({ cnpj, setCnpj }) => {
  return (
    <Input
      size="lg"
      isRequired
      errorMessage={({ validationDetails }) => {
        if (validationDetails.valueMissing) {
          return "Por favor, digite o seu CNPJ";
        }
        if (validationDetails.typeMismatch) {
          return "Por favor, digite um CNPJ válido";
        }
      }}
      label="CNPJ"
      labelPlacement="outside"
      name="CNPJ"
      placeholder="Digite o seu CNPJ"
      value={formatCNPJ(cnpj)}
      onValueChange={setCnpj}
      type="text"
      minLength={14}
    />
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  isInvalid,
  errorMessage,
  variant = "faded",
  label = "Senha",
  placeholder = "Digite sua senha",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Input
      size="lg"
      type={isVisible ? "text" : "password"}
      endContent={
        <PasswordToggle
          isVisible={isVisible}
          onClick={() => setIsVisible(!isVisible)}
        />
      }
      label={label}
      variant={variant}
      value={password}
      onValueChange={setPassword}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      placeholder={placeholder}
      isRequired
    />
  );
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [maskedValue, setMaskedValue] = useState("");

  // sync with firebase api
  useEffect(
    () => setMaskedValue(normalizePhoneNumber(cleanPhoneNumber(value))),
    [value]
  );

  const handleChange = useCallback(
    (inputValue: string) => {
      const cleanedValue = cleanPhoneNumber(inputValue);
      const formatted = normalizePhoneNumber(cleanedValue);
      setMaskedValue(formatted);
      onChange(cleanedValue);
    },
    [onChange]
  );

  return (
    <Input
      {...props}
      value={maskedValue}
      onValueChange={handleChange}
      placeholder="(XX) XXXXX-XXXX"
      type="tel"
      maxLength={15}
      autoComplete="tel-national"
      startContent={<span className="text-default-400 text-small">+55</span>}
    />
  );
};

// Separate Slot component for better organization
const Slot = ({
  char,
  isActive,
  isComplete,
  ...props
}: SlotProps & { isComplete?: boolean }) => {
  return (
    <motion.div
      {...props}
      className={` relative w-11 h-12 flex items-center justify-center rounded-large transition-[box-shadow,colors,border-color] text-lg font-medium bg-transparent group  border-2 ${
        isActive ? "ring-2 ring-primary/80 ring-offset-2" : "ring-0"
      } ${char ? "border-primary" : "border-default-200"} ${
        isComplete
          ? "border-success data-[has-value=true]:border-success ring-success/80 "
          : ""
      } shadow-sm hover:shadow-md cursor-text data-[has-value=true]:border-primary data-[focus=true]:border-primary data-[invalid=true]:border-danger data-[invalid=true]:group-data-[focus=true]:border-danger focus:border-primary
      `}
      variants={inputItemVariants}
      data-has-value={!!char}
      data-focus={isActive}
    >
      {char || <div className="w-2 h-2 rounded-full bg-default-300 absolute" />}
    </motion.div>
  );
};

export const OTPInput: React.FC<OTPInputProps> = ({
  verificationCode,
  setVerificationCode,
}) => {
  const isComplete = verificationCode.length === 6;

  const handleOTPChange = (value: string) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    setVerificationCode(numericValue);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-default-700">
            Código de Verificação
          </p>
          <p className="text-xs text-default-500">
            Digite o código de 6 dígitos enviado por SMS
          </p>
        </div>
        <AnimatePresence mode="wait">
          {isComplete ? (
            <motion.div
              key="complete"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="bg-success/20 text-success px-2 py-1 rounded-full flex items-center gap-1"
            >
              <Check size={12} />
              <span className="text-xs font-medium">6/6</span>
            </motion.div>
          ) : (
            <motion.div
              key="counting"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="flex items-center gap-1"
            >
              <div className="w-4 h-4 rounded-full bg-primary/20 flex items-center justify-center">
                <span className="text-[10px] text-primary font-medium">
                  {verificationCode.length}
                </span>
              </div>
              <span className="text-xs text-default-500">/</span>
              <span className="text-xs text-default-500">6</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        variants={inputContainerVariants}
        initial="initial"
        animate="animate"
        className="flex justify-center"
      >
        <InputOTP
          value={verificationCode}
          onChange={handleOTPChange}
          maxLength={6}
          pattern="\d*"
          inputMode="tel"
          containerClassName="gap-1 justify-center py-1"
          render={({ slots }) => (
            <div className="flex gap-1.5">
              {slots.map((slot, idx) => (
                <Slot key={idx} {...slot} isComplete={isComplete} />
              ))}
            </div>
          )}
        />
      </motion.div>
    </div>
  );
};

export const GoogleMapsInput = ({
  onPlaceSelect,
  ...props
}: GoogleMapsInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteService = useRef<any>(null);
  const placesService = useRef<any>(null);
  const debounceTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (typeof window.google === "undefined") {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${getEnv(
        "VITE_GOOGLE_MAPS_API_KEY"
      )}&libraries=places`;
      script.async = true;
      script.onload = initializeServices;
      document.head.appendChild(script);
    } else {
      initializeServices();
    }

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, []);

  const initializeServices = () => {
    if (!window.google) return;

    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  };

  const fetchSuggestions = (input: string) => {
    if (!autocompleteService.current || input.length < 3) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    autocompleteService.current.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: "br" },
        types: ["geocode", "establishment"],
      },
      (predictions: any[], status: string) => {
        setIsLoading(false);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions || []);
        } else {
          setSuggestions([]);
        }
      }
    );
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => fetchSuggestions(value), 300);
  };

  const handleSelection = (key: React.Key | null) => {
    if (key === null) return;

    const selected = suggestions.find((item) => item.place_id === key);
    if (!selected || !placesService.current) return;

    placesService.current.getDetails(
      { placeId: selected.place_id },
      (place: any, status: string) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          onPlaceSelect(place.place_id, place.formatted_address);
          setInputValue(place.formatted_address);
        }
      }
    );
  };

  return (
    <Autocomplete
      {...props}
      size="lg"
      radius="md"
      inputValue={inputValue}
      isLoading={isLoading}
      onInputChange={handleInputChange}
      onSelectionChange={handleSelection}
      allowsCustomValue
    >
      {suggestions.map((prediction) => (
        <AutocompleteItem
          key={prediction.place_id}
          textValue={prediction.description}
          variant="flat"
          color="primary"
        >
          {prediction.description}
        </AutocompleteItem>
      ))}
    </Autocomplete>
  );
};

export const ProfessionalCouncilInput: React.FC<
  ProfessionalCouncilInputProps
> = ({ value, onChange, onBlur, isInvalid, errorMessage, labelPlacement }) => {
  const handleAreaChange = (area: string) => {
    onChange("area", area);
    // Define automaticamente o conselho correspondente
    if (area && areaToCouncils[area]) {
      onChange("conselho", areaToCouncils[area]);
    }
  };

  return (
    <div className="space-y-4">
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex gap-4 max-sm:flex-col"
      >
        <div className="flex-1">
          <Select
            label="Área de Atuação"
            labelPlacement={labelPlacement}
            placeholder="Selecione sua área"
            selectedKeys={value.area ? [value.area] : []}
            onSelectionChange={(keys) => {
              const selectedArea = Array.from(keys)[0] as string;
              handleAreaChange(selectedArea);
            }}
            variant="flat"
            size="lg"
            isRequired
            isInvalid={isInvalid}
            errorMessage={errorMessage}
            onBlur={onBlur}
            classNames={{
              value: "flex items-center gap-2",
            }}
          >
            {areaOptions.map((area) => (
              <SelectItem
                key={area}
                textValue={`${area} - ${areaToCouncils[area]}`}
                variant="flat"
              >
                <div className="flex items-center justify-between w-full">
                  <span>{area}</span>
                  <span className="text-default-500">
                    {areaToCouncils[area]}
                  </span>
                </div>
              </SelectItem>
            ))}
          </Select>
        </div>
      </motion.div>

      {value.area && value.conselho && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex gap-4 max-sm:flex-col"
        >
          <Input
            label="Número do Registro"
            placeholder="Preencha o número"
            className="flex-1"
            value={value.numero}
            onValueChange={(value) => onChange("numero", value)}
            variant="bordered"
            size="lg"
            isRequired
            isInvalid={isInvalid}
            errorMessage={errorMessage}
            onBlur={onBlur}
          />

          <Select
            label="Estado de Registro"
            placeholder="Selecione o estado"
            className="flex-1"
            selectedKeys={value.estado ? [value.estado] : []}
            onSelectionChange={(keys) =>
              onChange("estado", Array.from(keys)[0] as string)
            }
            variant="bordered"
            size="lg"
            isRequired
            isInvalid={isInvalid}
            errorMessage={errorMessage}
            onBlur={onBlur}
          >
            {states.map((state) => (
              <SelectItem key={state} value={state} variant="flat">
                {state}
              </SelectItem>
            ))}
          </Select>
        </motion.div>
      )}
    </div>
  );
};
