import React, { useState, useEffect } from "react";
import { Button, Switch } from "@nextui-org/react";
import { Drawer } from "vaul";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { fireStoreDb } from "../../../services/firebase/firebase";
import { toast } from "react-toastify";
import { Check } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { ZipBio } from "../../../models/ZipBio";
import { PhoneNumberInput } from "../../Molecules/Inputs";
import { getPlatformDetails } from "../../../utils/platformUtils";

interface EditRedirectDrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  initialData: {
    id: string;
    redirectMethods: {
      whatsApp: boolean;
      zipSaude: boolean;
    };
    contactMethods: Array<{
      id: string;
      platform: string;
      link: string;
    }>;
  };
  providerId: string;
  onSuccess: () => void;
}

export const EditRedirectDrawer: React.FC<EditRedirectDrawerProps> = ({
  isOpen,
  onOpenChange,
  initialData,
  providerId,
  onSuccess,
}) => {
  const [redirectMethods, setRedirectMethods] = useState(
    initialData.redirectMethods
  );
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [showWhatsappInput, setShowWhatsappInput] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [inputError, setInputError] = useState("");

  const existingWhatsAppMethod = initialData.contactMethods.find(
    (method) => method.platform === "whatsapp"
  );

  const whatsappConfig = getPlatformDetails("whatsapp");

  useEffect(() => {
    if (isOpen) {
      setRedirectMethods(initialData.redirectMethods);
      setWhatsappNumber("");
      setShowWhatsappInput(false);
      setSaveSuccess(false);
      setInputError("");
    }
  }, [isOpen, initialData]);

  const validateWhatsAppNumber = (value: string): boolean => {
    if (!value.trim()) {
      setInputError("Número do WhatsApp é obrigatório");
      return false;
    }

    if (!whatsappConfig.validate?.(value)) {
      setInputError("Número de WhatsApp inválido");
      return false;
    }

    setInputError("");
    return true;
  };

  const handleToggleChange = (key: "whatsApp" | "zipSaude") => {
    const newValue = !redirectMethods[key];
    const otherKey = key === "whatsApp" ? "zipSaude" : "whatsApp";

    // If trying to disable the only enabled option, enable the other one
    if (!newValue && !redirectMethods[otherKey]) {
      setRedirectMethods((prev) => ({
        ...prev,
        [key]: false,
        [otherKey]: true,
      }));
    } else {
      setRedirectMethods((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    }

    // Show WhatsApp input if enabling WhatsApp and no existing WhatsApp method
    if (key === "whatsApp" && newValue && !existingWhatsAppMethod) {
      setShowWhatsappInput(true);
    }
  };

  const handleSave = async () => {
    if (
      redirectMethods.whatsApp &&
      !existingWhatsAppMethod &&
      !whatsappNumber &&
      showWhatsappInput
    ) {
      if (!validateWhatsAppNumber(whatsappNumber)) {
        return;
      }
    }

    setIsSaving(true);

    try {
      const docRef = doc(fireStoreDb, "zipBios", providerId);
      const updates: Partial<ZipBio> = {
        redirectMethods,
        updatedAt: new Date(),
      };

      // If enabling WhatsApp and no existing method, add the new contact method
      if (
        redirectMethods.whatsApp &&
        !existingWhatsAppMethod &&
        whatsappNumber
      ) {
        const cleanedNumber =
          whatsappConfig.clean?.(whatsappNumber) ?? whatsappNumber;
        const whatsappLink =
          whatsappConfig.getLinkFromValue?.(cleanedNumber) ?? "";

        const newWhatsAppMethod = {
          id: Date.now().toString(),
          platform: "whatsapp",
          link: whatsappLink,
        };

        await updateDoc(docRef, {
          ...updates,
          contactMethods: arrayUnion(newWhatsAppMethod),
        });
      } else {
        await updateDoc(docRef, updates);
      }

      setSaveSuccess(true);
      toast.success("Formas de Agendamento atualizadas!");

      // Delay closing and reset
      setTimeout(() => {
        onSuccess();
        onOpenChange(false);
        setSaveSuccess(false);
      }, 1500);
    } catch (error) {
      console.error("Error updating redirect settings:", error);
      toast.error("Erro ao atualizar configurações. Tente novamente.");
      setSaveSuccess(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-10 bg-black/75 backdrop-blur-sm" />
        <Drawer.Content className="bg-white flex flex-col rounded-t-large z-50 mt-24 fixed bottom-0 left-0 right-0">
          <Drawer.Handle className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-default-300 my-4" />
          <div className="p-4 bg-white rounded-t-large flex-1">
            <Drawer.Title className="text-center text-xl font-semibold text-default-900 mb-8 px-0.5">
              Configurar Formas de Agendamento
            </Drawer.Title>
            <div className="max-w-md mx-auto flex flex-col gap-12">
              <div className="flex flex-col gap-6">
                <Switch
                  size="lg"
                  color="secondary"
                  isSelected={redirectMethods.zipSaude}
                  onValueChange={() => handleToggleChange("zipSaude")}
                >
                  <div className="flex flex-col">
                    <span className="text-small">ZipSaúde</span>
                    <span className="text-tiny text-default-500">
                      Usar sistema de agendamento do ZipSaúde
                    </span>
                  </div>
                </Switch>

                <Switch
                  size="lg"
                  color="secondary"
                  isSelected={redirectMethods.whatsApp}
                  onValueChange={() => handleToggleChange("whatsApp")}
                >
                  <div className="flex flex-col">
                    <span className="text-small">WhatsApp</span>
                    <span className="text-tiny text-default-500">
                      Redirecionar para WhatsApp
                    </span>
                  </div>
                </Switch>

                <AnimatePresence>
                  {showWhatsappInput && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      className="overflow-hidden"
                    >
                      <PhoneNumberInput
                        size="lg"
                        radius="md"
                        variant="flat"
                        label={whatsappConfig.label}
                        placeholder={whatsappConfig.placeholder}
                        value={whatsappNumber}
                        onChange={(value) => {
                          const cleanValue =
                            whatsappConfig.clean?.(value) ?? value;
                          setWhatsappNumber(cleanValue);
                          validateWhatsAppNumber(cleanValue);
                        }}
                        isInvalid={!!inputError}
                        errorMessage={inputError}
                      />
                      {whatsappConfig.helperText && (
                        <p className="text-xs text-default-500 mt-1 px-1">
                          {whatsappConfig.helperText}
                        </p>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <motion.div
                layout
                animate={{ scale: saveSuccess ? 0.95 : 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                <Button
                  color={saveSuccess ? "success" : "primary"}
                  className="w-full"
                  size="lg"
                  radius="md"
                  onPress={handleSave}
                  isLoading={isSaving}
                  isDisabled={saveSuccess}
                >
                  <AnimatePresence mode="wait">
                    {saveSuccess ? (
                      <motion.div
                        key="success"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        className="flex items-center gap-2"
                      >
                        <Check size={20} />
                        <span>Configurações Salvas!</span>
                      </motion.div>
                    ) : (
                      <motion.div
                        key="save"
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                      >
                        {isSaving ? "Salvando..." : "Salvar Configurações"}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Button>
              </motion.div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
