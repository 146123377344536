import { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../contexts/auth.context";
import { useClinicDetailsQuery } from "../../../hooks/queries/useClinicDetailsQuery";
import { useProvidersQuery } from "../../../hooks/queries/useProviderQuery";
import { useScheduleAppointment } from "../../../hooks/queries/useScheduleAppointment";
import { BookingSkeleton } from "../../Organisms/BookingSkeleton";
import { BookingError } from "../../Organisms/BookingError";
import { BookingTemplate } from "../../Templates/BookingTemplate";
import { BookingHeader } from "../../Organisms/BookingHeader";
import { useDisclosure } from "@nextui-org/react";
import { BookingModal } from "../../Organisms/BookingModal";

export const ClinicBooking = () => {
  const { clinicId } = useParams();
  const [searchParams] = useSearchParams();
  const examId = searchParams.get("exam");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(null);

  const handleFormDataChange = useCallback((newFormData: any) => {
    setFormData(newFormData);
  }, []);

  const { currentUser } = useAuth();

  const { data: clinicDetails, isLoading: isLoadingClinic } =
    useClinicDetailsQuery(clinicId);
  const { data: providersList, isLoading: isLoadingProviders } =
    useProvidersQuery(clinicId, examId as string);

  const scheduleAppointment = useScheduleAppointment();

  if (isLoadingClinic || isLoadingProviders) {
    return <BookingSkeleton />;
  }
  if (!clinicDetails) return <BookingError />;

  return (
    <BookingTemplate
      title="Agendar Exame"
      hasReviews={false}
      header={
        <BookingHeader
          clinic={clinicDetails}
          acceptedPaymentMethods={clinicDetails.acceptedPaymentMethods}
          contactInfo={{
            phoneNumber: clinicDetails.phoneNumber as string,
            name: clinicDetails.name as string,
            address: clinicDetails.address as string,
            timezone: clinicDetails.timezone,
          }}
          userTimezone={currentUser.timezone}
          onOpen={onOpen}
        />
      }
    >
      <BookingModal
        isOpen={isOpen}
        onClose={onClose}
        isClinicBooking={true}
        clinicDetails={clinicDetails}
        currentUser={currentUser}
        scheduleAppointment={scheduleAppointment}
        providersList={providersList}
        offeredExams={clinicDetails.schedules?.exams?.exams}
        formData={formData}
        onFormDataChange={handleFormDataChange}
      />
    </BookingTemplate>
  );
};
