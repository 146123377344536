import {
  Chip,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { Star } from "lucide-react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const RatingChip = ({ rating }: { rating: number }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (!rating) {
    return (
      <Popover placement="bottom" showArrow>
        <PopoverTrigger>
          <Chip
            variant="flat"
            color="primary"
            classNames={{
              content: "font-semibold px-1.5",
              base: isMobile
                ? "active:opacity-70"
                : "hover:opacity-70 cursor-help",
            }}
          >
            <Star className="w-4" />
          </Chip>
        </PopoverTrigger>
        <PopoverContent>
          <div className="px-1 py-2 text-sm">
            Este profissional ainda não tem avaliações
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Chip
      variant="flat"
      color="primary"
      startContent={<Star className="w-4" />}
      classNames={{ content: "font-semibold px-1" }}
    >
      {rating}
    </Chip>
  );
};
